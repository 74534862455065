import React, {useEffect, useRef, useState} from "react";
import {fetchReopenQueue} from "../../Functions/ReopenQueueFunctions";
import ReopenQueueModal from "../../components/Modals/RepoenQueueModal";
import {fetchResponses} from "../../Functions/Responses";
import {fetchBuyersList} from "../../Functions/BuyersList";

export default function ReopenQueue() {
    const[filtered, setFiltered] = useState();
    const[filterOpen, setFilterOpen] = useState(false);
    const[queue_id, setQueueId] = useState();
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[reopenQueuefilters, setReopenQueuefilters] = useState({
        project_id: localStorage.getItem('projectId'),
        id: "",
        seller_request_id: "",
        reason: "",
        status: "Open",
        user_id: "",
    });
    const[reopenQueueModalOpen, setReopenQueueModalOpen] = useState(false);
    const[reopenQueue,setReopenQueue] = useState([]);
    const[ReopenQueueFilterOpen, setReopenQueueFilterOpen] = useState(false);
    const[responses, setResponses] = useState([]);
    const[sellerRequest, setSellerRequest] = useState();
    const[sellerRequestId, setSellerRequestId] = useState();
    const[updateReopenList, setUpdateReopenList] = useState();
    const filterRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            // Close the modal here
            setFilterOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function clearFilter() {
        const emptyFilterData = Object.keys(reopenQueuefilters).reduce((acc, key) => {
            if (key === "project_id") {
                acc[key] = localStorage.getItem('projectId');
            } else {
                acc[key] = "";
            }
            return acc;
        }, {});
        setReopenQueuefilters(emptyFilterData);
        setUpdateReopenList(true);
        setFiltered(false);
    }

    const hendleFetchResponses = (e) => {
        for (const key in reopenQueuefilters) {
            if (reopenQueuefilters[key] !== "") {
                setFiltered(true);
                break;
            }
        }
        fetchReopenQueue(reopenQueuefilters, setReopenQueue, setReopenQueueFilterOpen, setUpdateReopenList);
        setUpdateReopenList(false);
        setFilterOpen(false);
    }

    useEffect(hendleFetchResponses, [updateReopenList]);

    function openReopenQueueModel(queue_id, seller_request_id, seller_request, responses) {
        setQueueId(queue_id);
        setSellerRequest(seller_request);
        setSellerRequestId(seller_request_id);
        setResponses(responses);
        setReopenQueueModalOpen(true);
    }

    return <>
        <ReopenQueueModal
            queue_id={queue_id}
            sellerRequestId={sellerRequestId}
            sellerRequest={sellerRequest}
            reopenQueueModalOpen={reopenQueueModalOpen}
            setReopenQueueModalOpen={setReopenQueueModalOpen}
            setUpdateReopenList={setUpdateReopenList}
            responses={responses}
        />

        {projectId && <>
            <button className="grayButton" onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
            {filtered && <button className='grayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
            {!filtered && <button className='lightGrayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
            {filterOpen ? (
                <ul className="menu fortySixPercentMenu" ref={filterRef}>
                    <li className="menu-item">
                        Status <br />
                        <select name="filterlinkedRequests" defaultValue={reopenQueuefilters.status} onChange={(e) =>
                            setReopenQueuefilters( {...reopenQueuefilters, status: e.target.value})}>
                            <option value="Open">Open</option>
                            <option value="Approved">Approved</option>
                            <option value="Rejected">Rejected</option>
                            <option value="">All</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={hendleFetchResponses}>Apply</button>
                    </li>
                </ul>
            ) : null}
            <h2><div style={{color:"#3178C6"}}>Project {localStorage.getItem('projectname')}</div><div style={{color:"gray"}}>Reopen Queue</div></h2>
            <table className="sellerListTable">
                <thead>
                <tr className="outline">
                    <th className="outline">User</th>
                    <th className="outline">Reason</th>
                    <th className="outline">Created</th>
                    <th className="outline">Seller Req ID</th>
                    <th className="outline">Request Name</th>
                    <th className="outline">Details</th>
                    <th className="outline">Reopen</th>
                    <th className="outline"></th>
                </tr>
                </thead>
                <tbody>
                {reopenQueue && (reopenQueue).map((queue,i)=>{
                    const seller_request = queue.seller_request;
                    const responses = queue.responses;
                    return (
                        <tr key={queue.id} className="outline">
                            <td className="outline">{queue.user_name}</td>
                            <td className="outline">{queue.reason}</td>
                            <td className="outline">{queue.time_created}</td>
                            <td className="outline">{seller_request.seller_request_id}</td>
                            <td className="outline">{seller_request.data_requested}</td>
                            <td className="outline">{seller_request.details}</td>
                            <td className="outline">{queue.status}</td>
                            <td className="outline">
                                <button onClick={()=>
                                { openReopenQueueModel(queue.id, queue.seller_request_id, seller_request, responses) }}>Reopen
                                </button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>}
    </>
}