import {React, useEffect, useState, useRef} from "react";
import {useForm} from "react-hook-form";
import Modal from "react-modal";
import {fetchUsers, fetchUser, saveUserData} from '../../Functions/UsersFunctions';
import { fetchEntities } from '../../Functions/EntityFunctions';
import {fetchRoles} from "../../Functions/RoleFunctions";
import {customStyles} from "../../Constants";
import ImportUserModal from "../../components/Modals/ImportUserModal";

export default function User() {
    const { register, handleSubmit, reset , setValue} = useForm();
    const[, setEntityFilterOpen] = useState();
    const[action, setAction] = useState();
    const[entityRowData, setEntityRowData] = useState([]);
    const[entityFilteredData, ] = useState({code: "", name: "", active: "", entity_type_id:""});
    const[entityListSorting, ] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
    });
    const[, setEntitySortOpen] = useState(false);
    const[error, setError] = useState();
    const[filtered, setFiltered] = useState();
    const[userFilteredData, setUserFilteredData] = useState({
        active: "",
        user_name: "",
        first_name: "",
        last_name:"",
        entity_id:""
    });

    const[filterOpen, setFilterOpen] = useState();
    const[importUserModalOpen,setImportUserModalOpen] = useState(false);
    const[modalIsOpen, setIsOpen] = useState(false);
    const[notification, setNotification] = useState();
    const[roleFilterData, setRoleFilterData] = useState({name: ""});
    const[roles, setRoles] = useState([]);
    const[sorted, setSorted] = useState(false);
    const[updateUserList, setUpdateUserList] = useState(false);
    const[user, setUser] = useState([]);
    const[userRowData, setUserRowData] = useState([]);
    const[userListSorting, setUserListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
    });
    const sort_array = [
        {id: "first_name", name: "First Name"},
        {id: "nickname", name: "Nickname"},
        {id: "last_name", name: "Last name"},
        {id: "suffix", name: "Suffix"},
        {id: "user_name", name: "Email/Username"},
        {id: "user_active", name: "Active"},
        {id: "entity.entity_name", name: "Entity"},
    ];

    const[sortOpen, setSortOpen] = useState();
    const filterRef = useRef(null);
    const sortRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            // Close the modal here
            setFilterOpen(false);
        }
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            // Close the modal here
            setSortOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleUserFetch = (e) => {
        for (const key in userFilteredData) {
            if (userFilteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        for (const key in userListSorting) {
            if (userListSorting[key] !== "" && userListSorting[key] !== "asc") {
                setSorted(true);
                break;
            }
        }

        fetchUsers(setUserRowData, userFilteredData, setFilterOpen, userListSorting, setSortOpen);
        setUpdateUserList(false);
    };

    useEffect( handleUserFetch, [updateUserList]);
    useEffect(() => fetchRoles(roleFilterData, setRoles, setFilterOpen), [])
    useEffect(() => { fetchEntities(setEntityRowData, entityFilteredData, setEntityFilterOpen, entityListSorting, setEntitySortOpen); }, []);

    function clearFilter() {
        const emptyFilterData = Object.keys(userFilteredData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setUserFilteredData(emptyFilterData);
        setFiltered(false);
        setUpdateUserList(true);
    }

    function clearSort() {
        const emptySortingData = Object.keys(userListSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setUserListSorting(emptySortingData);
        setUpdateUserList(true);
        setSorted(false);
    }

    function fetchUserData(id) {
        setError(false);
        setAction('PUT');
        setIsOpen(true);
        fetchUser(id, setUser);
    }

    function createNewUser() {
        setIsOpen(!modalIsOpen);
        setAction('POST');
        setUser([]);
    }

    function closeUserModal() {
        setUser({});
        setError();
        setIsOpen(false);
        reset();
    }
    return <>
        <h2>User Management</h2>
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            ariaHideApp={false}
        >
            {action === 'PUT' && <h2>Update User</h2>}
            {action === 'POST' && <h2>New User</h2>}
            <button className="grayButton"  onClick={() => closeUserModal()}>Close</button>
            {error && <h1 className="userError">{error}</h1>}
            <form onSubmit={handleSubmit(data =>
                saveUserData(data, setError, setUpdateUserList, setIsOpen, reset, action))}>
                {setValue('id', user.id)}

                <input name="id" {...register('id', {value: user.id})} hidden/>
                {action == "PUT" && setValue('active', user.active)}

                <label>Entity</label>
                {action === "PUT" && <p>{user.entity_name}</p> }
                {action === "POST" && <>
                    <select defaultValue={user.entity_id} {...register('entity_id')} >
                        <option value={0}>Select Entity</option>
                        {entityRowData && entityRowData.map((entityData,i)=>{
                            return (
                                <option value={entityData.id}>{entityData.entity_name}</option>
                            )
                        })}
                    </select>
                </>}

                <label>Active</label>
                <select name="active" {...register("active")}>
                    <option value={false}>Inactive</option>
                    <option value={true}>Active</option>
                </select>

                <label>Email/Username</label>
                {action == "PUT" && setValue('user_name', user.user_name)}
                <input name="user_name" {...register('user_name')} />

                {action == "PUT" && setValue('first_name', user.first_name)}
                <label>First Name</label>
                <input name="first_name" {...register('first_name')} />

                <label>Nickname</label>
                {action == "PUT" && setValue('nickname', user.nickname)}
                <input name="nickname" {...register('nickname')} />

                <label>Last Name</label>
                {action == "PUT" && setValue('last_name', user.last_name)}
                <input name="last_name" {...register('last_name')} />

                <label>Suffix</label>
                {action == "PUT" && setValue('suffix', user.suffix)}
                <input name="suffix" {...register('suffix')} />

                {action === "PUT" && <>
                    <label>Reset One Time Passcode</label>
                    <select defaultValue={false} {...register('reset_otp_secret')}>
                        <option value={false}>No</option>
                        <option value={true}>Yes</option>
                    </select>
                </>}
                {action === 'POST' && <>
                    <label>Password</label>
                <input type="password" name="credential" {...register('credential')} />

                <label>Verify Password</label>
                <input type="password" name="verify_credential" {...register('verify_credential')} />
                </>}
                
                {action === 'PUT' && Object.keys(user).length > 0 && Object.keys(user.roles).length > 0 && setValue('role', user.roles[0]['id'])}
                {action === 'POST' && setValue('role',0)}
                <label>Role</label>
                <select name="role" {...register('role')} >
                    <option value={0}>Select Role</option>
                    {roles && roles.map((role,i)=>{
                        return (
                            <option key={role.id} value={role.id}>{role.name}</option>
                        )
                    })}
                </select>

                {action === 'POST' && <input type="submit" value="Create User" className="saveNewBuyerRequestButton"/>}
                {action === 'PUT' && <input type="submit" value="Update User" className="saveNewBuyerRequestButton"/>}
            </form>
        </Modal>
        <ImportUserModal
            importUserModalOpen={importUserModalOpen}
            setImportUserModalOpen={setImportUserModalOpen}
            setUpdateUserList={setUpdateUserList}
            setNotification={setNotification}
        />

        <div>
            {notification && <h1 className="userError">{notification}</h1>}
            <button className='grayButton' onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
            {filtered && <button className='grayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
            {!filtered && <button className='lightGrayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <button className='grayButton' onClick={() => setSortOpen(!sortOpen)}>Sort</button>
            {sorted && <button className='grayButton' onClick={()=> clearSort()}>Clear Sort</button>}
            {!sorted && <button className='lightGrayButton' onClick={()=> clearSort()}>Clear Sort</button>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <button className="blueButton" onClick={() => createNewUser()} > Create New User </button>
            <button className="blueButton" onClick={() => setImportUserModalOpen(true)}>Import Users</button>
            {filterOpen ? (
                <ul className="menu thirtyOnePercentMenu" ref={filterRef}>
                    {filtered && <h1>Data is Filtered</h1>}

                    <li className="menu-item">
                        First Name
                        <input type="text" defaultValue={userFilteredData.first_name} placeholder="First name" onChange={(e) =>
                            setUserFilteredData({...userFilteredData, first_name:  e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Nickame
                        <input type="text" defaultValue={userFilteredData.nickname} placeholder="Nickname" onChange={(e) =>
                            setUserFilteredData({...userFilteredData, nickname:  e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Last Name
                        <input type="text" defaultValue={userFilteredData.last_name} placeholder="Last Name" onChange={(e) =>
                            setUserFilteredData({...userFilteredData, last_name:  e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Suffix
                        <input type="text" defaultValue={userFilteredData.suffix} placeholder="Suffix" onChange={(e) =>
                            setUserFilteredData({...userFilteredData, suffix:  e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Email/Username
                        <input type="text" defaultValue={userFilteredData.user_name} placeholder="User Name" onChange={(e) =>
                            setUserFilteredData( {...userFilteredData, user_name: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Active <br/>
                        <select defaultValue={userFilteredData.active} onChange={(e) =>
                            setUserFilteredData( {...userFilteredData, active:  e.target.value})} >
                            <option>Select Active/Inactive</option>
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Entity <br/>
                        <select defaultValue={userFilteredData.entity_id} onChange={(e) =>
                            setUserFilteredData( {...userFilteredData, entity_id:  e.target.value})} >
                            <option>Select Entity</option>
                            {entityRowData && entityRowData.map((entityData,i)=>{
                                return (
                                    <option value={entityData.id}>{entityData.entity_name}</option>
                                )
                            })}
                        </select>
                    </li>



                    <li>
                        <button onClick={() => handleUserFetch()}>Apply</button>
                    </li>
                </ul>
            ) : null}
            {sortOpen ? (
                <ul className="menu fortyThreePercentMenu" ref={sortRef}>
                    <li className="menu-item">
                        Sort By
                        <select name="id" defaultValue={userListSorting.sort_one}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_one: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>

                        <select name="sort_one_direction" defaultValue={userListSorting.sort_one_direction}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_one_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_two" defaultValue={userListSorting.sort_two}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_two: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_two_direction" defaultValue={userListSorting.sort_two_direction}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_two_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_three" defaultValue={userListSorting.sort_three}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_three: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_three_direction" defaultValue={userListSorting.sort_three_direction}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_three_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_four" defaultValue={userListSorting.sort_four}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_four: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_four_direction" defaultValue={userListSorting.sort_four_direction}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_four_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_five" defaultValue={userListSorting.sort_five}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_five: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_five_direction" defaultValue={userListSorting.sort_five_direction}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_five_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_six" defaultValue={userListSorting.sort_six}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_six: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_six_direction" defaultValue={userListSorting.sort_six_direction}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_six_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_seven" defaultValue={userListSorting.sort_seven}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_seven: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_seven_direction" defaultValue={userListSorting.sort_seven_direction}
                                onChange={(e) =>
                                    setUserListSorting({...userListSorting, sort_seven_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={() => handleUserFetch()}>Apply</button>
                    </li>
                </ul>
            ) : null}
        </div>
        <table className="userListTable">
            <thead>
            <tr className="outline">
                <td className="outline">First Name</td>
                <td className="outline">Nickname</td>
                <td className="outline">Last Name</td>
                <td className="outline">Suffix</td>
                <td className="outline">Email/Username</td>
                <td className="outline">Entity</td>
                <td className="outline">Active</td>
                <td className="outline">Role</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {userRowData && userRowData.map((userData,i)=>{
                const userRoles = userData.roles;
                return (
                    <tr key={userData.id} className="outline">
                        <td className="outline">{userData.first_name}</td>
                        <td className="outline">{userData.nickname}</td>
                        <td className="outline">{userData.last_name}</td>
                        <td className="outline">{userData.suffix}</td>
                        <td className="outline">{userData.user_name}</td>
                        <td className="outline">{userData.entity}</td>
                        <td className="outline">{userData.active ? "Active" : "Inactive"}</td>
                        <td className="outline">
                            {userRoles && userRoles.map((userRole)=>{
                                return userRole.name
                            })}
                        </td>
                        <td>
                            <button className='grayButton' onClick={() => {fetchUserData(userData.id)}}>
                                Edit
                            </button>
                        </td>
                    </tr>
                )
            })}

            </tbody>
        </table>
    </>

}