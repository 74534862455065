import {React, useEffect, useState} from "react";
import './ThirdPartyAssignment.css';
import { fetchThirdPartyAssignment, fetchThirdPartyAssignments } from "../../Functions/ThirdPartyFunctions";
import NewThirdPartyAssignmentModal from "../../components/Modals/NewThirdPartyAssignmentModal";

export default function ThirdPartyAssignment() {
    const[action, setAction] = useState('POST');
    const[, setError]  = useState();
    const[modalIsOpen, setIsOpen] = useState(false);
    const[thirdPartyAssignment, setThirdPartyAssignment] = useState([]);
    const[thirdPartyAssignments, setThirdPartyAssignments] = useState([]);
    const[thirdPartyUpdate, setThirdPartyUpdate] = useState();

    useEffect(() => {
        fetchThirdPartyAssignments(setThirdPartyAssignments);
        setThirdPartyUpdate(false);
    }, [thirdPartyUpdate]);

    function openThirdPartyAssignmentModal() {
        setIsOpen(true);
        setAction("POST");
    }

    function fetchThirdPartyAssignmentData(id) {
        setError(false);
        setAction('PUT');
        setIsOpen(true);
        fetchThirdPartyAssignment(id, setThirdPartyAssignment, setError);

    }

    return <>
        <h2>Third Party Assignment</h2>
        <NewThirdPartyAssignmentModal
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            action={action}
            setThirdPartyAssignments={setThirdPartyAssignments}
            thirdPartyAssignment={thirdPartyAssignment}
            setThirdPartyAssignment={setThirdPartyAssignment}
            setThirdPartyUpdate={setThirdPartyUpdate}
        ></NewThirdPartyAssignmentModal>
        <button className="blueButton" onClick={() => {openThirdPartyAssignmentModal()}}>
            Assign Third Party
        </button>

        <table className="entityListTable">
            <thead>
            <tr className="outline">
                <td className="outline">ID</td>
                <td className="outline">Project</td>
                <td className="outline">Code</td>
                <td className="outline">Name</td>
                <td className="outline">Assignment Type</td>
                <td className="outline">Active</td>
                <td className="outline">Email List</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {thirdPartyAssignments && thirdPartyAssignments.map((thirdPartyAssignment,i)=>{
                let assignment = "";
                if (thirdPartyAssignment.entity_type == 'buyer') {
                    assignment = 'Buyer Advisor';
                } else {
                    assignment = 'Seller Advisor';
                }
                return (
                    <tr key={thirdPartyAssignment.id} className="outline">
                        <td className="outline">{thirdPartyAssignment.id}</td>
                        <td className="outline">{thirdPartyAssignment.project}</td>
                        <td className="outline">{thirdPartyAssignment.code}</td>
                        <td className="outline">{thirdPartyAssignment.name}</td>
                        <td className="outline">{assignment}</td>
                        <td className="outline">{thirdPartyAssignment.active ? "Active" : "Inactive"}</td>
                        <td className="outline">{thirdPartyAssignment.email_list}</td>
                        <td><button className="grayButton" onClick={() => {fetchThirdPartyAssignmentData(thirdPartyAssignment.id)}}>
                            Edit
                        </button></td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    </>
}