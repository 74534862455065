import axios from "axios";
const token = localStorage.getItem("accessToken");

export function fetchRoles(roleFilterData, setRoles, setFilterOpen){
    let params = "";
    if (roleFilterData.name !== "") { params += "&name="+ roleFilterData.name; }

    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/roles?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setRoles(response['data']['roles']);
            setFilterOpen(false);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function fetchRole(id, setRole, setFilterOpen) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/roles/'+ id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setRole(response['data']['role']);
            setFilterOpen(false);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function saveRole(data, roleFilterData, setRoles, setFilterOpen, setError, setIsOpen, reset, action) {
    if (action === "POST") {
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/roles', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                fetchRoles(roleFilterData, setRoles, setFilterOpen);
                setIsOpen(false);
                setError(false);
                reset();
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    } else if (action === "PUT") {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/roles/' + data.id, data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                fetchRoles(roleFilterData, setRoles, setFilterOpen);
                setIsOpen(false);
                setError(false);
                reset();
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
}