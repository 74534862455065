import Modal from "react-modal";
import {customStyles, DATE_RANGE, NEW_RESPONSE_FORM} from "../../Constants";
import {getResponseData, saveNewResponse} from "../../Functions/Responses";
import Dropzone from "../FileUploader/Dropzone";
import {React, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import useLocalStorageInt from "../../Functions/useLocalStorageInt";
import {fetchStatuses} from "../../Functions/StatusFunction";
import {calculate_date_time} from "../../Functions/DateTimeFunctions";
import {downloadFile} from "../../Functions/Files";


export default function AddResponseModal({ResponseModalOpen, setOpenResponseModal, setFiles, sellerRequest, sellerResponseRequestId, files, setUpdateList}) {
    const { register, handleSubmit , reset, setValue} = useForm();
    const[documentsUploading, setDocumentsUploading] = useLocalStorageInt("documentsUploading", localStorage.getItem("documentsUploading"));
    const[isLoading, setIsLoading] = useState(false);
    const[responseError, setResponseError] = useState();
    const[sellerResponseViewData, setSellerResponseViewData] = useState([]);
    const[statuses, setStatuses] = useState([]);
    const[updateResponseData, setUpdateResponseData] = useState(true);
    const[, setError] = useState();

    const handleFetchResponses = (e) => {
        getResponseData(sellerResponseRequestId, setSellerResponseViewData)
        setUpdateResponseData(false);
    }

    useEffect(() => fetchStatuses(setStatuses), []);
    useEffect(() => { if (ResponseModalOpen) { handleFetchResponses(); } }, [ResponseModalOpen]);
    useEffect(handleFetchResponses, [updateResponseData]);

    function closeNewResponseModal() {
        reset();
        setOpenResponseModal(false)
        setFiles([]);
        setResponseError();
        setIsLoading(false);
    }

    return <>
        <Modal
            isOpen={ResponseModalOpen}
            onRequestClose={() => closeNewResponseModal()}
            style={customStyles}
            contentLabel={NEW_RESPONSE_FORM}
            ariaHideApp={false}
        >
            <h2>{NEW_RESPONSE_FORM}</h2>
            <button className="grayButton" onClick={() => closeNewResponseModal()}>Close</button>
            {isLoading && <h1 className="redButton">Loading....</h1>}
            {sellerRequest &&
                <div>
                    <table>
                        <thead>
                        <tr className="outline">
                            <th className="outline">Phase</th>
                            <th className="outline">Requested On</th>
                            <th className="outline">Seller Req ID</th>
                            <th className="outline">Category</th>
                            <th className="outline">Request Name</th>
                            <th className="outline">Request Details</th>
                            <th className="outline">{DATE_RANGE}</th>
                            <th className="outline">Preferred File Type</th>
                            <th className="outline">Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr key={sellerRequest.id} className="outline">
                            <td className="outline">{sellerRequest.batch}</td>
                            <td className="outline">{sellerRequest.assigned_date}</td>
                            <td className="outline">{sellerRequest.seller_request_id}</td>
                            <td className="outline">{sellerRequest.category}</td>
                            <td className="outline">{sellerRequest.data_requested}</td>
                            <td className="outline">{sellerRequest.details}</td>
                            <td className="outline">{sellerRequest.parameters}</td>
                            <td className="outline">{sellerRequest.file_format}</td>
                            <td className="outline">{sellerRequest.status}</td>
                        </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <br />

                    <h1>Responses</h1>
                    <table className='sellerListTable'>
                        <thead>
                        <tr>
                            <th className="outline">Response ID</th>
                            <th className="outline">Uploaded Date</th>
                            <th className="outline">Uploaded By</th>
                            <th className="outline">Comments</th>
                            <th className="outline">Last User Download</th>
                            <th className="outline">Last Company Download</th>
                            <th className="outline">Document</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sellerResponseViewData && sellerResponseViewData.map((sellerResponses,i)=>{
                            const documents = sellerResponses.docstorage;
                            let last_user_download = '';
                            let last_entity_download = '';
                            let time_created = '';
                            if (sellerResponses.last_user_download !== '') { last_user_download = calculate_date_time(sellerResponses.last_user_download); }
                            if (sellerResponses.last_entity_download !== '') { last_entity_download = calculate_date_time(sellerResponses.last_entity_download); }
                            if (sellerResponses.time_created !== '') { time_created = calculate_date_time(sellerResponses.time_created); }
                            let display = false;
                            if (sellerResponses.status === 'Approved') {
                                display = true;
                            }
                            let response_details = sellerResponses.response_details;
                            if (sellerResponses.response_type == 'buyer'
                                && sellerResponses.edited_response !== ''
                                && sellerResponses.edited_response != undefined) {
                                response_details = sellerResponses.edited_response;
                            }
                            return ( display &&
                                <>
                                    <tr key={sellerResponses.id}>
                                        <td className="outline">{sellerResponses.seller_response_id}</td>
                                        <td className="outline">{time_created}</td>
                                        <td className="outline">{sellerResponses.user}</td>
                                        <td className="outline">{response_details}</td>
                                        <td className="outline">{last_user_download}</td>
                                        <td className="outline">{last_entity_download}</td>
                                        <td className="outline">
                                            {documents && documents.map((document, )=>{
                                                const rand = Boolean(Math.round(Math.random()));
                                                const formStyle = {
                                                    display: "contents"
                                                }
                                                return (<>
                                                    <form onSubmit={handleSubmit(data => downloadFile(document.blob_name_raw, document.blob_name))}
                                                          style={formStyle}>
                                                        <input type="submit" value={document.blob_name} className="grayButton"/>
                                                    </form>
                                                </>)
                                            })
                                            }
                                        </td>

                                    </tr>
                                </>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            }
            {responseError && <h1 className="redButton">{responseError}</h1>}
                <form onSubmit={handleSubmit(data =>
                    saveNewResponse(data, setDocumentsUploading, parseInt(documentsUploading), setResponseError, files,
                        setFiles, setIsLoading, setError, setOpenResponseModal, reset, setUpdateList))}>
                    <input name="response_type" defaultValue="seller" {...register('response_type')} hidden />
                    <input name="approved" defaultValue={true} {...register('approved')} hidden />
                    {setValue('seller_request_id', sellerResponseRequestId)}
                    <input name="seller_request_id" {...register('seller_request_id')} hidden />

                    <label>Request Status</label>
                    <select name="status_id" {...register('status_id')}>
                        <option value={0}>Please Select a Status</option>
                        {statuses.map((status,i) => {
                            return (
                                <option key={status.id} value={status.id}>{status.status}</option>
                            )
                        })}
                    </select>

                    <label>Details <br /><em>Any pertinent information that might be needed</em></label>
                    <textarea rows={10} name="response_details" {...register('response_details')} />

                    <input type="submit" value="Save Response" className="saveNewSellerRequestButton"/>

                    <Dropzone myFiles={files} setMyFiles={setFiles} width="300px" height='400px'/>
                </form>
        </Modal>
    </>
}