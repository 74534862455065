import axios from "axios";
import {define_sorting} from "./Generic";
const token = localStorage.getItem("accessToken");

export function deleteBuyersList(id, projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen) {
    axios.delete(process.env.REACT_APP_API_BASE_URL + '/api/v1/buyer_request_list/' + id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            fetchBuyersList(projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen)
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function fetchBuyersList(projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen, sorting, setSortOpen){
    let params = 'project_id=' + projectId;
    if (buyerFilteredData) {
        if (buyerFilteredData.requestId !== "") { params += "&request_id="+ buyerFilteredData.requestId; }
        if (buyerFilteredData.buyer_request_list_id !== "") { params += "&buyer_request_list_id="+ buyerFilteredData.buyer_request_list_id; }
        if (buyerFilteredData.request_name  !== "" ) { params += "&request_name=" + encodeURIComponent(buyerFilteredData.request_name );}
        if (buyerFilteredData.date_range !== "" ) { params += "&date_range=" + encodeURIComponent(buyerFilteredData.date_range); }
        if (buyerFilteredData.category !== "") { params += "&category=" + buyerFilteredData.category; }
        if (buyerFilteredData.priority !== "") { params += "&priority=" + buyerFilteredData.priority; }
        if (buyerFilteredData.linkedRequests !== "") { params += "&linked=" + buyerFilteredData.linkedRequests; }
        if (buyerFilteredData.entity_id !== "") { params += "&entity_id=" + buyerFilteredData.entity_id; }
        if (buyerFilteredData.details !== "" && buyerFilteredData.details !== "") { params += "&details=" + encodeURIComponent(buyerFilteredData.details); }
        if (buyerFilteredData.seller_request_id !== "") { params += "&seller_request_id=" + buyerFilteredData.seller_request_id; }
        if (buyerFilteredData.status_id !== "") { params += "&status_id=" + buyerFilteredData.status_id; }
    }
    params = define_sorting(params, sorting);
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/buyer_request_list?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setBuyersListRowData(response['data']['buyer_request_lists']);
            setFilterOpen(false);
            setSortOpen(false);
        })
        .catch(function (error) {
            console.log(error);
        })
};

export function fetchBuyerRequest(id, setBuyerRequest) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/buyer_request_list/' + id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setBuyerRequest(response['data']['buyer_request_list']);
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function saveBuyersList(data, setNewBuyerRequestError, reset, resetResponse, setIsOpen, projectId,
                               buyerFilteredData, setBuyersListRowData, setFilterOpen, action) {
    data['requested_date'] = new Date().toISOString().split("T")[0];
    if (localStorage.getItem('projectId') === null) { setNewBuyerRequestError("You must select a project"); return; }
    if (data['entity_id'] === '0') { setNewBuyerRequestError("You must select a Requesting Entity"); return; }
    if (data['category'] === '0') { setNewBuyerRequestError("You must enter a Category"); return; }
    if (data['request_name'] === '' ) { setNewBuyerRequestError("You must enter Request Name"); return; }
    if (data['details'] === '' ) { setNewBuyerRequestError("You must enter Request Details"); return; }
    if (data['date_range'] === '' ) { setNewBuyerRequestError("You must enter Scope"); return; }
    if (data['file_type_id'] === '0' ) { setNewBuyerRequestError("You must select a Preferred File Format"); return; }
    data['file_type_id'] = parseInt(data['file_type_id']);
    data['entity_id'] = parseInt(data['entity_id']);
    if (action === "POST") {
        data['project_id'] = parseInt(localStorage.getItem('projectId'));
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/buyer_request_list', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                setNewBuyerRequestError(false);
                fetchBuyersList(projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen)
                reset();
                if(resetResponse) { resetResponse(); }
                setIsOpen(false);
            })
            .catch(function (error) {
                if (error.response) {
                    setNewBuyerRequestError(error.response.data.detail);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    } else if (action === "PUT") {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/buyer_request_list/'+ data.id, data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                setNewBuyerRequestError(false);
                fetchBuyersList(projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen)
                reset();
                if(resetResponse) { resetResponse(); }
                setIsOpen(false);
            })
            .catch(function (error) {
                if (error.response) {
                    setNewBuyerRequestError(error.response.data.detail);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
}