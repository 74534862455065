import * as CONSTANTS from "../Constants";
import axios from "axios";
import {define_sorting} from "./Generic";

const token = localStorage.getItem("accessToken");

export function fetchEntities(setEntities, filteredData, setFilterOpen, sorting, setSortOpen) {
    let params = "";
    if (filteredData !== undefined) {
        Object.keys(filteredData).forEach(key => {
            if (filteredData[key] && filteredData[key] !== 'Select Active/Inactive' && filteredData[key] !== 'Entity Type') {
                params += `&${key}=${filteredData[key]}`;
            }
        });
    }

    params = define_sorting(params, sorting);
    if (params) {
        params = params.substring(1);
    }

    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/entities?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setEntities(response['data']['entities']);
            if(setFilterOpen) { setFilterOpen(false); }
            if(setSortOpen) { setSortOpen(false); }
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function fetchEntity(id, setEntity, setError) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/entities/' + id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setEntity(response.data.entity);
            setError(false);
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function saveEntity(setError, reset, setIsOpen, data, setUpdateEntityList, action) {
    if (data['code'] === "") { setError(CONSTANTS.CODE_ERROR_MESSAGE); return; }
    if (data['entity_name'] === "") { setError(CONSTANTS.NAME_ERROR); return; }
    if (data['entity_type_id'] === "0") { console.log("are we here"); setError(CONSTANTS.INVALID_ENTITY_TYPE); return; }
    if (data['active'] === "Select Active/Inactive") { setError(CONSTANTS.ACTIVE_ERROR_MESSAGE); return; }
    data['entity_type_id'] = parseInt(data['entity_type_id']);

    if (action === 'POST') {
        data.active = data.active === 'true';
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/entities', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                setUpdateEntityList(true);
                setError(false);
                reset();
                setIsOpen(false);
            })
            .catch(function (error) {
                if (error.response) {
                    setError(error.response.data.detail);
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    } else if (action === 'PUT') {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/entities/'+ data.id, data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(response => {
                setUpdateEntityList(true);
                setError(false);
                reset();
                setIsOpen(false);
            })
            .catch(function (error) {
                if (error.response) {
                    setError(error.response.data.detail);
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
}