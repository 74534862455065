import Modal from "react-modal";
import {React, useEffect, useState} from "react";
import {customStyles} from "../../Constants";
import {saveEntity, fetchEntities} from "../../Functions/EntityFunctions";
import {useForm} from "react-hook-form";
import {fetchEntityTypes} from "../../Functions/EntityTypes";

export default function NewEntity({modalIsOpen, setIsOpen, action, setEntityRowData, setUpdateEntityList, entity, filteredData}) {
    const { register, handleSubmit, reset , setValue} = useForm();
    const[entityTypes, setEntityTypes] = useState([]);
    const[error, setError] = useState(false);

    useEffect( () => {
        fetchEntityTypes(setEntityTypes);
    }, []);

    return (
        <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
            {action === 'PUT' && <h2>Update Entity</h2>}
            {action === 'POST' && <h2>New Entity</h2>}
            {error && <h1 className="userError">{error}</h1>}
            <button className="grayButton" onClick={() => setIsOpen(false)}>Close</button>
            <form onSubmit={handleSubmit(data =>
                saveEntity(setError, reset, setIsOpen, data, setUpdateEntityList, action)
            )}>
                {action === "PUT" && setValue('id', entity.id)}
                <input name="id" {...register('id')} hidden />

                {action == "PUT" && setValue('code', entity.code)}
                <label>Code</label>
                <input name="code" {...register('code')} />

                {action == "PUT" && setValue('entity_name', entity.entity_name)}
                <label>Name</label>
                <input name="entity_name" {...register('entity_name')} />

                {action == "PUT" && setValue('entity_type_id', entity.entity_type_id)}
                <label>Entity Type</label>
                <select name="entity_type_id" {...register('entity_type_id')} >
                    <option value={0}>Select An Entity Type</option>
                    {entityTypes && entityTypes.map((entityType,i)=> {
                        return (
                            <option key={entityType.id} value={entityType.id}>{entityType.entity_type}</option>
                        )
                    })}
                </select>

                {action == "PUT" && setValue('active', entity.active)}
                <label>Active</label>
                <select name="active" {...register('active')} >
                    <option key="newEntityFalse" value={false}>Inactive</option>
                    <option key="newEntityTrue" value={true}>Active</option>
                </select>

                {action === 'POST' && <input type="submit" value="Create New Entity" className="saveNewBuyerRequestButton"/>}
                {action === 'PUT' && <input type="submit" value="Update Entity" className="saveNewBuyerRequestButton"/>}
            </form>
        </Modal>
    )
}
