import axios from "axios";
const token = localStorage.getItem("accessToken");
export function fetchEntityTypes(setEntityTypes) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/entity_types',
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setEntityTypes(response['data']['entity_types']);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function fetchEntityTypesId(setEntityTypeId, entityType) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/entity_types?entity_type=' + entityType,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            const responseData = response.data.entity_types[0];
            setEntityTypeId(responseData.id);
        })
        .catch(function (error) {
            console.log(error);
        });
}