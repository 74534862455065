import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import { customStyles } from "../../Constants";
import { fetchThirdPartyAssignments, saveThirdPartyAssignments } from "../../Functions/ThirdPartyFunctions";
import { useForm } from "react-hook-form";
import { fetchEntities } from "../../Functions/EntityFunctions";
import { fetchProjects } from "../../Functions/ProjectsFunctions";
import { fetchEntityTypes } from "../../Functions/EntityTypes";
import fetchCategories from "../../Functions/CategoryFunctions";

function NewThirdPartyAssignmentModal({ modalIsOpen, setIsOpen, action, setThirdPartyAssignments, thirdPartyAssignment, setThirdPartyAssignment,
                                          setThirdPartyUpdate }) {
    const { register, handleSubmit, reset, setValue } = useForm();
    const [advisorType, setAdvisorType] = useState(thirdPartyAssignment.entity_type);
    const [categories, setCategories] = useState([]);
    const [entityFilteredData, setEntityFilteredData] = useState({ code: "", name: "", active: "", entity_type_id: "" });
    const [entityTypes, setEntityTypes] = useState([]);
    const [error, setError] = useState();
    const [, setFilterOpen] = useState();
    const [projects, setProjects] = useState([]);
    const [thirdParties, setThirdParties] = useState([]);
    const [updateEntityList, setUpdateEntityList] = useState();
    const [sortOpen, setSortOpen] = useState();
    const [entityListSorting, setEntityListSorting] = useState({
        sort_one: "code",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction: "asc",
        sort_three: "",
        sort_three_direction: "asc",
        sort_four: "",
        sort_four_direction: "asc",
    });
    const [checkedCategories, setCheckedCategories] = useState([]);

    useEffect(() => {
        setAdvisorType(thirdPartyAssignment.entity_type);
        setValue('entity_type', thirdPartyAssignment.entity_type);
        setValue('active', thirdPartyAssignment.active);
        setValue('email_list', thirdPartyAssignment.email_list);
        setValue('id', thirdPartyAssignment.id);

        if (thirdPartyAssignment && thirdPartyAssignment.category_id != null) {
            let initialCheckedCategories = thirdPartyAssignment.category_id;
            if (typeof initialCheckedCategories === 'string') {
                initialCheckedCategories = initialCheckedCategories
                    .replace(/[{}]/g, '')
                    .split(',')
                    .map(item => parseInt(item, 10))
                    .filter(item => !isNaN(item)); // Filter out NaN values
            }
            setCheckedCategories(initialCheckedCategories);
        }
    }, [thirdPartyAssignment, setValue]);

    useEffect(() => { fetchEntityTypes(setEntityTypes); }, []);
    useEffect(() => { fetchProjects([], setProjects); }, []);
    useEffect(() => { fetchCategories(setCategories); }, []);

    useEffect(() => {
        fetchThirdPartyAssignments(setThirdPartyAssignments);
        const thirdPartyEntityId = entityTypes.find(entityType => entityType['entity_type'] === 'Third Party');
        setEntityFilteredData({ ...entityFilteredData, active: true });
        if (thirdPartyEntityId) {
            setEntityFilteredData({ ...entityFilteredData, entity_type_id: thirdPartyEntityId.id });
        }
        fetchEntities(setThirdParties, entityFilteredData, setFilterOpen, entityListSorting, setSortOpen);
    }, [updateEntityList, entityTypes]);

    const handleAdvisorTypeChange = (event) => {
        setAdvisorType(event.target.value);
        setValue('entity_type', event.target.value);
    };

    const handleCheckboxChange = (categoryId) => {
        setCheckedCategories((prevCheckedCategories) => {
            if (prevCheckedCategories.includes(categoryId)) {
                return prevCheckedCategories.filter((id) => id !== categoryId);
            } else {
                return [...prevCheckedCategories, categoryId];
            }
        });
    };

    const ThirdpartyAssignmentCheckboxes = ({ category }) => {
        return (
            <p>
                <input
                    key={category.id}
                    type="checkbox"
                    value={category.id}
                    style={{ width: "revert" }}
                    checked={checkedCategories.includes(category.id)}
                    onChange={() => handleCheckboxChange(category.id)}
                />
                {category.category_name}
            </p>
        );
    };

    function closeModal() {
        setAdvisorType("");
        setThirdPartyAssignment([]);
        reset();
        setIsOpen(false);
    }

    return (
        <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
            {action === 'PUT' && <h2>Update Third Party</h2>}
            {action === 'POST' && <h2>Add Third Party</h2>}
            {error && <h1 className="userError">{error}</h1>}
            <button className="grayButton" onClick={() => closeModal()}>Close</button>
            <form onSubmit={handleSubmit(data => {
                data.category_id = checkedCategories.length ? checkedCategories : null; // Set None if no category_id
                saveThirdPartyAssignments(data, setError, reset, setIsOpen, action, setThirdPartyUpdate);
            })}>
                {action === 'PUT' && <input name="id" {...register('id')} hidden />}
                {action === "POST" && <>
                    <label>Project</label>
                    <select name="project_id" {...register('project_id')} >
                        <option value={0}>Select A Project</option>
                        {projects && projects.map((project, i) => (
                            <option key={i} value={project.id}>{project.project_name}</option>
                        ))}
                    </select>
                </>}

                {action === 'PUT' && <p>Third Party: {thirdPartyAssignment.name}</p>}
                {action === "POST" && <>
                    <label>Third Party</label>
                    <select name="third_party_id" {...register('third_party_id')} >
                        <option value={0}>Select A Third Party</option>
                        {thirdParties && thirdParties.map((thirdParty, i) => (
                            <option key={i} value={thirdParty.id}>{thirdParty.entity_name}</option>
                        ))}
                    </select>
                </>}
                <label>Project Assignment Status</label>
                <select name="active" {...register('active')} >
                    <option value={false}>Inactive</option>
                    <option value={true}>Active</option>
                </select>
                <label>Advisor Type</label>
                <select name="entity_type" {...register('entity_type')} onChange={handleAdvisorTypeChange}>
                    <option value={0}>Select Advisor Type</option>
                    <option value="buyer">Buyer Advisor</option>
                    <option value="seller">Seller Advisor</option>
                </select>
                <label>Email List <em>Comma Separated List</em></label>
                <input name="email_list" {...register('email_list')} />
                {advisorType === 'seller' && <>
                    {categories && (
                        <label>
                            Categories
                            <br />
                            <em>For email purposes, leave blank for all emails</em>
                        </label>
                    )}
                    {categories && categories.map((category, i) => (
                        <ThirdpartyAssignmentCheckboxes key={i} category={category} />
                    ))}
                </>}
                {advisorType === 'buyer' && <>
                    {setValue('category_list', thirdPartyAssignment.category_list)}
                    <label>Buyer Advisor Categories <em>Comma Delimited List (IT, Legal, etc)</em></label>
                    <input name="category_list" {...register('category_list')} />
                </>}

                {action === 'POST' && <input type="submit" value="Add Third Party" className="saveNewBuyerRequestButton" />}
                {action === 'PUT' && <input type="submit" value="Update Third Party" className="saveNewBuyerRequestButton" />}
            </form>
        </Modal>
    );
}

export default NewThirdPartyAssignmentModal;
