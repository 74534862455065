import Modal from "react-modal";
import {React, useEffect, useRef, useState} from "react";
import {customStyles} from "../../Constants";
import {useForm} from "react-hook-form";
import {saveNewResponse} from "../../Functions/Responses";
import {updateReopenQueue} from "../../Functions/ReopenQueueFunctions";
import {calculate_date_time} from "../../Functions/DateTimeFunctions";
import {downloadFile} from "../../Functions/Files";


export default function ReopenQueueModal({queue_id, sellerRequestId, reopenQueueModalOpen, setReopenQueueModalOpen,
                                             setUpdateReopenList, sellerRequest, responses}) {
    const { register, handleSubmit , reset} = useForm();
    const[, fetchData] = useState();
    const[, setUpdateList] = useState();
    const[, setOpenResponseModal] = useState();
    const[documentsUploading, setDocumentsUploading] = useState();
    const[files, setFiles] = useState();
    const[error, setError] = useState();
    const[isLoading, setIsLoading] = useState(false);
    const[reopen, setReopen] = useState(false);
    const[responseError,setResponseError] = useState();
    const[status, setStatus] = useState(false);
    const hiddenSubmitRef = useRef(null);

    const onSubmit = data => {
        data.status = status;
        reopenSellerRequest(data);
        setError(false);
    };

    const handleButtonClick = (statusValue, reopenValue) => {
        setStatus(statusValue);
        setReopen(reopenValue);
        setTimeout(() => {
            hiddenSubmitRef.current.click();
        }, 0);
    };

    const handleReopenClick = () => {
        setReopen(true);
        setStatus("Approved");
    };

    const handleRejectClick = () => {
        setReopen(false);
        setStatus("Rejected");
    };

    function closeReopenQueueModalOpen() {
        setStatus(false);
        setResponseError(false);
        setReopenQueueModalOpen(false);
    }

    function reopenSellerRequest(data) {
        data.status = status;
        data.reopen = reopen;
        if (data.response_details === undefined || data.response_details === '') {
            setError("You must enter a reason");
            return;
        }
        if (data.status === false) {
            setError("A status should be automatically selected");
            return;
        }
        setReopenQueueModalOpen(false);
        updateReopenQueue(queue_id, data, setUpdateReopenList);

        const responseData = {
            seller_request_id:sellerRequestId,
            response_details:data['response_details'],
            response_type:"seller",
            response_id: ""
        }
        saveNewResponse(responseData, setDocumentsUploading, documentsUploading, setResponseError, files, setFiles,
            setIsLoading, setError, setOpenResponseModal, reset, setUpdateList);

        reset();
        setError(false);
        setStatus(false);
        setResponseError(false);
    }

    return <>
        <Modal
            isOpen={reopenQueueModalOpen}
            style={customStyles}
            contentLabel="Reopen Seller Request"
            ariaHideApp={false}
        >
            <h2>Reopen Seller Request</h2>
            {isLoading && <h1>Loading....</h1>}
            {error && <h1 className="userError">{error}</h1>}
            {responseError && <h1 className="userError">{responseError}</h1>}

            <button className="grayButton" onClick={() => closeReopenQueueModalOpen()}>Close</button>
            <table className="sellerListTable">
                <thead>
                <tr className="outline">
                    <th className="outline">Phase</th>
                    <th className="outline">Requested On</th>
                    <th className="outline">Seller Req ID</th>
                    <th className="outline">Category</th>
                    <th className="outline">Request Name</th>
                    <th className="outline">Request Details</th>
                    <th className="outline">Date Range</th>
                    <th className="outline">Preferred File Type</th>
                    <th className="outline">Status</th>
                    <th className="outline" colSpan={3}></th>
                </tr>
                </thead>
                <tbody>
                {sellerRequest &&
                        <tr key={sellerRequest.id} className="outline">
                            <td className="outline">{sellerRequest.batch}</td>
                            <td className="outline">{sellerRequest.assigned_date}</td>
                            <td className="outline">{sellerRequest.seller_request_id}</td>
                            <td className="outline">{sellerRequest.category}</td>
                            <td className="outline">{sellerRequest.data_requested}</td>
                            <td className="outline">{sellerRequest.details}</td>
                            <td className="outline">{sellerRequest.parameters}</td>
                            <td className="outline">{sellerRequest.file_format}</td>
                            <td className="outline">{sellerRequest.status}</td>
                        </tr>
                }
                </tbody>
            </table>
            <br />
            <br />
            <br />
            <h1>Responses</h1>
            <table className='sellerListTable'>
                <thead>
                <tr>
                    <th className="outline">Response ID</th>
                    <th className="outline">Uploaded Date</th>
                    <th className="outline">Uploaded By</th>
                    <th className="outline">Comments</th>
                    <th className="outline">Last User Download</th>
                    <th className="outline">Last Company Download</th>
                    <th className="outline">Document</th>
                </tr>
                </thead>
                <tbody>
                {responses && responses.map((sellerResponses,i)=>{
                    const documents = sellerResponses.docstorage;
                    let last_user_download = '';
                    let last_entity_download = '';
                    let time_created = '';
                    if (sellerResponses.last_user_download !== '' && sellerResponses.last_user_download !== undefined) { last_user_download = calculate_date_time(sellerResponses.last_user_download); }
                    if (sellerResponses.last_entity_download !== '' && sellerResponses.last_entity_download !== undefined) { last_entity_download = calculate_date_time(sellerResponses.last_entity_download); }
                    if (sellerResponses.time_created !== '' && sellerResponses.time_created !== undefined) { time_created = calculate_date_time(sellerResponses.time_created); }

                    return (
                        <>
                            <tr key={sellerResponses.id}>
                                <td className="outline">{sellerResponses.seller_response_id}</td>
                                <td className="outline">{time_created}</td>
                                <td className="outline">{sellerResponses.user}</td>
                                <td className="outline">{sellerResponses.response_details}</td>
                                <td className="outline">{last_user_download}</td>
                                <td className="outline">{last_entity_download}</td>
                                <td className="outline">
                                    {documents && documents.map((document, )=>{
                                        const rand = Boolean(Math.round(Math.random()));
                                        const formStyle = {
                                            display: "contents"
                                        }
                                        return (<>
                                            <form onSubmit={handleSubmit(data => downloadFile(document.blob_name_raw, document.blob_name))}
                                                  style={formStyle}>
                                                <input type="submit" value={document.blob_name} className="grayButton"/>
                                            </form>
                                        </>)
                                    })
                                    }
                                </td>

                            </tr>
                        </>
                    )
                })}
                </tbody>
            </table>
            <form onSubmit={handleSubmit(data =>
                reopenSellerRequest(data))}>
                <label>Response</label>
                <input name="response_details" {...register('response_details')} />
                <input
                    className="blueButton"
                    type="button"
                    value="Reopen"
                    onClick={() => handleButtonClick("Approved", true)}
                />
                <input
                    className="redButton"
                    type="button"
                    value="Reject"
                    onClick={() => handleButtonClick("Rejected", false)}
                />
                <button type="submit" ref={hiddenSubmitRef} style={{ display: "none" }} />
            </form>
        </Modal>
    </>
}