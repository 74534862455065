import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { fetchUser, saveUserData } from "../../Functions/UsersFunctions";
import { fetchProjects } from "../../Functions/ProjectsFunctions";
import { SELECT_PROJECT } from "../../Constants";

export default function Profile() {
    const { register, handleSubmit, reset, setValue } = useForm();
    const [, setFilterOpen] = useState();
    const [, setIsOpen] = useState();
    const [, setSortOpen] = useState(false);
    const [error, setError] = useState();
    const [filteredData, setFilteredData] = useState({
        "active": "1",
        "project_name": "",
        "seller_entity_id": "",
        "buyer_entity_id": "",
        "batch": "",
    });
    const [isDirty, setIsDirty] = useState(false);
    const [projects, setProjects] = useState([]);
    const [projectListSorting, setProjectListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction: "asc",
        sort_three: "",
        sort_three_direction: "asc",
        sort_four: "",
        sort_four_direction: "asc",
        sort_five: "",
        sort_five_direction: "asc",
        sort_six: "",
        sort_six_direction: "asc",
        sort_seven: "",
        sort_seven_direction: "asc",
    });
    const [selectedOption, setSelectedOption] = useState("");
    const [user, setUser] = useState();
    const [updateUser, setUpdateUser] = useState();
    const [formValues, setFormValues] = useState({});
    const projectId = localStorage.getItem("projectId");
    const userId = localStorage.getItem("userId");
    const selectStyle = { fontSize: 20 };
    const action = 'PUT';

    useEffect(() => {
        if (projectId) {
            setSelectedOption(projectId);
        }
    }, [projectId]);

    useEffect(() => {
        fetchProjects(filteredData, setProjects, setFilterOpen, projectListSorting, setSortOpen);
    }, [filteredData, projectListSorting]);

    useEffect(() => {
        fetchUser(userId, setUser);
    }, [userId, updateUser]);

    useEffect(() => {
        if (user) {
            setFormValues(user);
            Object.keys(user).forEach(key => setValue(key, user[key]));
        }
    }, [user, setValue]);

    const handleSelectChange = (event) => {
        const projectId = event.target.value;
        if (projectId === SELECT_PROJECT) {
            localStorage.removeItem('projectId');
            localStorage.removeItem('projectname');
        } else {
            const index = event.nativeEvent.target.selectedIndex;
            localStorage.setItem('projectId', projectId);
            localStorage.setItem('projectname', event.nativeEvent.target[index].text);
        }
        setSelectedOption(projectId);
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
        setValue(name, value, { shouldValidate: true });
        setIsDirty(true); // Mark form as dirty when any field is changed
    };

    return (
        <>
            <h2>Current Project</h2>
            <select style={selectStyle} onChange={handleSelectChange} value={selectedOption}>
                <option>{SELECT_PROJECT}</option>
                {projects && projects.map((project, i) => (
                    <option key={project.id} value={project.id}>{project.project_name}</option>
                ))}
            </select>
            <br />
            <br />
            <br />
            <h2>Profile</h2>
            {error && <h1 className="userError">{error}</h1>}
            <form onSubmit={handleSubmit(data =>
                saveUserData(data, setError, setUpdateUser, setIsOpen, reset, action, setIsDirty)
            )}>
                {user && setValue('id', user.id)}
                {user && <input name="id" defaultValue={user.id} {...register('id')} hidden />}
                {user && setValue('active', user.active)}
                {user && <input name="active" defaultValue={user.active} {...register('active')} hidden />}
                {user && setValue('user_name', user.user_name)}
                {user && <input name="user_name" defaultValue={user.user_name} {...register('user_name')} disabled={true} />}
                <label>First Name</label>
                <input name="first_name" value={formValues.first_name || ''} {...register('first_name')} onChange={handleFieldChange} />
                <label>Nickname</label>
                <input name="nickname" value={formValues.nickname || ''} {...register('nickname')} onChange={handleFieldChange} />
                <label>Last Name</label>
                <input name="last_name" value={formValues.last_name || ''} {...register('last_name')} onChange={handleFieldChange} />
                <label>Password</label>
                <input type='password' name="credential" value={formValues.credential || ''} {...register('credential')} onChange={handleFieldChange} />
                <label>Confirm Password</label>
                <input type='password' name="verify_credential" value={formValues.verify_credential || ''} {...register('verify_credential')} onChange={handleFieldChange} />
                <label>Entity</label>
                <input name="entity_name" value={formValues.entity_name || ''} {...register('entity_name')} disabled={true} />
                <input className={isDirty ? "blueButton" : "grayButton"} type="submit" value="Update Profile" />
            </form>
        </>
    );
}
