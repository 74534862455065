import axios from "axios";
import {define_sorting} from "./Generic";

const token = localStorage.getItem("accessToken");
export function fetchEmailQueue(projectId, emailQueueFilteredData, sorting, setEmailQueue, setFilterOpen, setSortOpen) {
    let params = "project_id=" + projectId;
    if (emailQueueFilteredData) {
        if (emailQueueFilteredData.recipient_entity_id && emailQueueFilteredData.recipient_entity_id != 'Select Recipient Entity') {params += "&recipient_entity_id="+emailQueueFilteredData.recipient_entity_id; }
        if (emailQueueFilteredData.subject) {params += "&subject="+emailQueueFilteredData.subject; }
        if (emailQueueFilteredData.template) {params += "&template="+emailQueueFilteredData.template; }
        if (emailQueueFilteredData.body) {params += "&body="+emailQueueFilteredData.body; }
        if (emailQueueFilteredData.time_created) {params += "&time_created="+emailQueueFilteredData.time_created; }
        if (emailQueueFilteredData.time_updated) {params += "&time_updated="+emailQueueFilteredData.time_updated; }
        if (emailQueueFilteredData.id) {params += "&id="+emailQueueFilteredData.id; }
        if (emailQueueFilteredData.interval) {params += "&interval="+emailQueueFilteredData.interval; }
        if (emailQueueFilteredData.sent_status) {params += "&sent_status="+emailQueueFilteredData.sent_status; }
    }
    params = define_sorting(params, sorting);
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/email_queue?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setEmailQueue(response['data']['email_queue']);
            setFilterOpen(false);
            setSortOpen(false);
        })
        .catch(function (error) {
            console.log(error);
        })
}