import axios from "axios";
import {define_sorting} from "./Generic";
const token = localStorage.getItem("accessToken");

export function downloadFile(blob_name_raw, fileName) {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + token);
    headers.append('responseType', 'blob');
    fetch(process.env.REACT_APP_API_BASE_URL + '/api/docstorage/v1/' + blob_name_raw,
        { headers })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);

            anchor.href = objectUrl;
            anchor.download = fileName;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        });
}

export function fetchDocuments(projectId, setDocuments, filterData, sorting, setFilterOpen, setSortOpen) {
    if (projectId === null) {
        setDocuments([]);
        return;
    }

    let params = 'project_id=' + projectId;
    if (filterData) {
        if (filterData.blob_deleted !== "") { params += "&blob_deleted="+ filterData.blob_deleted; }
        if (filterData.blob_name !== "") { params += "&blob_name="+ filterData.blob_name; }
        if (filterData.data_requested !== "") { params += "&data_requested="+ filterData.data_requested; }
        if (filterData.seller_request_id !== "") { params += "&seller_request_id="+ filterData.seller_request_id; }
        if (filterData.uploaded_by !== "") { params += "&uploaded_by="+ filterData.uploaded_by; }
        if (filterData.blob_creation_time !== "") { params += "&blob_creation_time="+ filterData.blob_creation_time; }
    }
    params = define_sorting(params, sorting);
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/documents?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            if (setDocuments) { setDocuments(response.data.documents); }
            if (setFilterOpen) {setFilterOpen(false); }
            if (setSortOpen) { setSortOpen(); }
        })
        .catch(function (error) {
            console.log(error);
        })
}
export function fetchFileTypes(setFileTypes) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/file_types',
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setFileTypes(response.data.file_types)
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function deleteFiles(document_id) {
    axios.delete(process.env.REACT_APP_API_BASE_URL + '/api/docstorage/v1/'+ document_id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {

        })
        .catch(function (error) {
            console.log(error);
        })
}