export function define_sorting(params, sorting) {
    if (sorting) {
        Object.keys(sorting).forEach(key => {
            if (sorting[key] !== "" && sorting[key] !== "0" && !key.endsWith("_direction")) {
                const directionKey = `${key}_direction`;
                if (sorting[directionKey]) {
                    params += `&sort=${sorting[key]},${sorting[directionKey]}`;
                }
            }
        });
    }
    return params
}

export const SortDropdown = ({ sortKey, sortDirectionKey, sortingState, setSortingState, sortArray }) => (
    <li className="menu-item"> {sortKey === 'sort_one' ? 'Sort By' : 'Then By'}
        <select name={sortKey} defaultValue={sortingState[sortKey]}
                onChange={(e) => setSortingState({ ...sortingState, [sortKey]: e.target.value })} >
            <option value={0}>Sort Column</option> {sortArray.map((sort) => ( <option key={sort.id} value={sort.id}>{sort.name}</option> ))}
        </select>
        <select
                name={sortDirectionKey}
                defaultValue={sortingState[sortDirectionKey]}
                onChange={(e) => setSortingState({ ...sortingState, [sortDirectionKey]: e.target.value })} >
            <option value="asc">Ascending</option> <option value="desc">Descending</option>
        </select>
    </li>
);