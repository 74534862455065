import React from 'react';
import './confirmationModal.css';

export default function ConfirmationModal({ message, onConfirm, onCancel }) {
    return (
        <div className="confirmationModal">
            <div className="modalContent">
                <p>{message}</p>
                <button className="blueButton" onClick={onConfirm}>Yes</button>
                <button className="grayButton" onClick={onCancel}>No</button>
            </div>
        </div>
    );
}
