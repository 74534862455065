import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';

export default function Dropzone({myFiles, setMyFiles, fileFormats}) {
    const onDrop = useCallback(acceptedFiles => {
        setMyFiles([...myFiles, ...acceptedFiles])
    }, [myFiles])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    })

    const removeFile = file => () => {
        const newFiles = [...myFiles]
        newFiles.splice(newFiles.indexOf(file), 1)
        setMyFiles(newFiles)
    }

    const removeAll = () => {
        setMyFiles([])
    }

    const files = myFiles.map(file => (
        <li key={file.path}>
            <button onClick={removeFile(file)}>Remove File</button> {file.path}
        </li>
    ))

    return (
        <section className="container">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>File Uploading can take some time.</p>
                <p>Drag and drop your files here</p>
                {fileFormats && <p>Supported files: {fileFormats}</p>}
                {!fileFormats && <p>Supported files: .PDF, .DOCX, .PPTX, .TXT, .XLSX</p>
                }
            </div>
            <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
            </aside>
            {files.length > 0 && <button onClick={removeAll}>Remove All</button>}
        </section>
    );
}