import * as ROLES from '../../Functions/RoleFunctions';
import {React, useEffect, useState} from "react";
import Modal from "react-modal";
import * as CONSTANTS from "../../Constants";
import {useForm} from "react-hook-form";
import * as PERMISSIONS from '../../Functions/PermissionsFunctions';

export default function Roles() {
    const { register, handleSubmit, reset , setValue} = useForm();
    const[action, setAction] = useState();
    const checkboxStyle = { display:"inline-block" };
    const[error, setError] = useState();
    const[filterOpen, setFilterOpen] = useState();
    const[modalIsOpen, setIsOpen] = useState(false);
    const[permissions, setPermissions] = useState();
    const[permissionFilterData, setPermissionFilterData] = useState({name: ""});
    const[permissionFilterOpen, setPermissionFilterOpen] = useState();
    const[roleFilterData, setRoleFilterData] = useState({name: ""});
    const[role, setRole] = useState([]);
    const[roles, setRoles] = useState([]);

    const customStyles = CONSTANTS.customStyles;
    const fetchPermissions = PERMISSIONS.fetchPermissions;
    const fetchRole = ROLES.fetchRole;
    const fetchRoles = ROLES.fetchRoles;
    const saveRole = ROLES.saveRole;

    useEffect(() => {
        fetchRoles(roleFilterData, setRoles, setFilterOpen);
        fetchPermissions(permissionFilterData, setPermissions, setPermissionFilterOpen);
    }, []);

    function createNewRole() {
        setIsOpen(!modalIsOpen);
        setAction('POST');
        setRole([]);
    }

    function fetchRoleData(id) {
        setError(false);
        setAction('PUT');
        setIsOpen(true);
        fetchRole(id, setRole, setFilterOpen);
    }

    return <>
        <h1>Role Management</h1>
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            ariaHideApp={false}
        >
            {action === 'PUT' && <h2>Update Role</h2>}
            {action === 'POST' && <h2>New Role</h2>}
            <button onClick={() => setIsOpen(false)}>close</button>
            {error && <h1 className="userError">{error}</h1>}
            <form onSubmit={handleSubmit(data => saveRole(
                data, roleFilterData, setRoles, setFilterOpen, setError, setIsOpen, reset, action))}
            >
                {setValue('id', role.id)}
                <input name="id" {...register('id', {value: role.id})} hidden />

                {setValue('name', role.name)}
                <label>Name</label>
                <input name="name" {...register('name')} />

                <label>Permissions</label>
                <table>
                    {permissions && permissions.map((permission)=>{
                        return <>
                            <tr className="outline">
                                <td>{permission.name}</td>
                                <td><input type="checkbox" id="checkbox" /></td>
                            </tr>
                        </>
                    })}
                </table>

                {action === 'POST' && <input type="submit" value="Create Role" className="saveNewBuyerRequestButton"/>}
                {action === 'PUT' && <input type="submit" value="Update Role" className="saveNewBuyerRequestButton"/>}
            </form>
        </Modal>
        <button className="newUserButton"
                onClick={() => createNewRole()}
        >
            Create New Role
        </button>
        <div> {/* Filter Code */}
            <button onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
            {filterOpen ? (
                <ul className="menu">
                    <li className="menu-item">
                        Code
                        <input type="text" defaultValue={roleFilterData.name} placeholder="Name" onChange={(e) =>
                            setRoleFilterData( {...roleFilterData, name: e.target.value})}>
                        </input>
                    </li>
                    <li>
                        <button onClick={() => fetchRoles(setRoles, roleFilterData, setFilterOpen)}>Apply</button>
                    </li>
                </ul>
            ) : null}
        </div>

        <table className="userListTable">
            <thead>
            <tr className="outline">
                <td className="outline">ID</td>
                <td className="outline">Role Name</td>
                <td className="outline">Permissions</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {roles && roles.map((role,i)=>{
                const rolePermissions = role.permissions;
                return (
                    <tr key={role.id} className="outline">
                        <td className="outline">{role.id}</td>
                        <td className="outline">{role.name}</td>
                        <td className="outline">
                            {rolePermissions && rolePermissions.map((permission,i, arr)=>{
                                return <span>{permission.name}{i != (arr.length-1) ? ', ' : ''}</span>
                            })}
                        </td>
                        <td><button onClick={() => {fetchRoleData(role.id)}}>
                            Edit
                        </button></td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    </>
}