import Modal from "react-modal";

function DeleteConfirmation({ handleDeleteTrue, handleDeleteFalse, deleteConfirmationModalOpen,  setdeleteConfirmationModalOpen}) {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <Modal
            isOpen={deleteConfirmationModalOpen}
            onRequestClose={() => setdeleteConfirmationModalOpen(false)}
            style={customStyles}
            contentLabel="Seller Responses"
            ariaHideApp={false}
        >
            <div className="modal">
                <div className="modal_box">
                    <p>Are you sure you want to delete this request?</p>
                    <button onClick={handleDeleteFalse} className="modal_buttonCancel">Cancel</button>
                    <button onClick={handleDeleteTrue} className="modal_buttoDelete">
                        Confirm
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default DeleteConfirmation;