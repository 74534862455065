import './App.css';
import React, {useState} from "react";
import Navigation from "./layout/Navigation/Navigation";
import Login from "./components/Login/Login";

function App() {
    const [token, setToken] = useState(localStorage.getItem("accessToken"));

    if(!token) {
        return <Login setToken={setToken} />
    }

  return (
    <div className="App">
        <Navigation></Navigation>
    </div>
  );
}

export default App;
