import React, {useEffect, useRef, useState} from "react";
import {fetchDuplicateQueue, updateDuplicateQueue} from "../../Functions/SellersList";
import Modal from "react-modal";
import {
    BUYER_PREFERRED_FILE_TYPE,
    BUYER_REQUEST_ID,
    customStyles,
    DATE_RANGE,
    DATE_RANGE_HELPER_TEXT,
    SELLER_REQUEST_ID
} from "../../Constants";
import fetchCategories from "../../Functions/CategoryFunctions";
import {fetchFileTypes} from "../../Functions/Files";
import {show} from "react-modal/lib/helpers/ariaAppHider";
import {fetchBuyersList} from "../../Functions/BuyersList";

export default function DuplicateQueue() {
    const[approveModal, setApproveModal] = useState(false);
    const[categories, setCategories]= useState({});
    const[duplicateID, setDuplicateID] = useState();
    const[duplicatesQueue, setDuplicateQueue] = useState([]);
    const[fileTypes, setFileTypes] = useState();
    const[filterOpen, setFilterOpen] = useState(false);
    const[filtered, setFiltered] = useState(false);
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[reason, setReason] = useState();
    const[rejectModal, setRejectModal] = useState(false);
    const[updateDuplicateQueueData, setUpdateDuplicateQueueData] = useState(false);
    const redTD = { backgroundColor: '#ffb3b3' };
    const greenTD = { backgroundColor: '#c6ecd9' };
    const[duplicateFilteredData, setDupeFilteredData] = useState({
        status: "Open",
        batch: "",
        priority: "",
        seller_request_id: "",
        buyer_request_id: "",
        category_id: "",
        data_requested: "",
        details: "",
        parameters: "",
        file_type_id: "",
        assigned_date: "",
    });
    const filterRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setFilterOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDuplicateQueueFetch = (e) => {
        for (const key in duplicateFilteredData) {
            if (duplicateFilteredData[key] !== "" && duplicateFilteredData['status'] !== "Open") {
                setFiltered(true);
                break;
            }
        }

        fetchDuplicateQueue(projectId, duplicateFilteredData, setDuplicateQueue, setFilterOpen);
        setUpdateDuplicateQueueData(false);
    };

    useEffect(() => { handleDuplicateQueueFetch(); }, [updateDuplicateQueueData]);

    useEffect(() => fetchCategories(setCategories), []);
    useEffect(() => fetchFileTypes(setFileTypes), []);

    function handleCloseRejectModal() {
        setRejectModal(false);
        setReason('');
    }

    function handleDuplicate(status) {
        let data = {};
        data.status = status;
        data.reason = reason;
        updateDuplicateQueue(duplicateID, projectId, duplicateFilteredData, setDuplicateQueue, setFilterOpen, data);
        setApproveModal(false);
        setRejectModal(false);
        setReason('');
    }

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    function openApproveModal(duplicateID) {
        setDuplicateID(duplicateID);
        setApproveModal(true);
    }

    function openRejectModal(duplicateID) {
        setDuplicateID(duplicateID);
        setRejectModal(true)
    }

    function clearFilter() {
        const emptyFilterData = Object.keys(duplicateFilteredData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setDupeFilteredData(emptyFilterData);
        handleDuplicateQueueFetch();
        setFiltered(false);
        setUpdateDuplicateQueueData(true);
    }

    return <>
        {projectId && <h2><div style={{color:"#3178C6"}}>Project {localStorage.getItem('projectname')}</div><div style={{color:"gray"}}>Duplicate Queue</div></h2>}
        {!projectId && <div>
            <h2><div style={{color:"red"}}>Project Not Selected</div>
                <div style={{color:"gray"}}>Duplicate Queue</div>
            </h2>
            <em style={{color:"red"}}>Please select a project on the Home page</em>
        </div>}

        <Modal isOpen={approveModal} style={customStyles} ariaHideApp={false}>
            <button className="grayButton"  onClick={() => setApproveModal(false)}>Close</button>
            <label>Reason to Approve</label>
            <input type="text" onChange={handleReasonChange} value={reason} />
            <p>Are you sure?</p>
            <button className="newEntityButton" onClick={() => {handleDuplicate("Approved")}}>
                Approve Duplicate
            </button>
        </Modal>

        <Modal isOpen={rejectModal} style={customStyles} ariaHideApp={false}>
            <button onClick={() => handleCloseRejectModal()}>close</button>
            <label>Reason to Reject</label>
            <input type="text" onChange={handleReasonChange} value={reason} />
            <p>Are you sure?</p>
            <button className="newEntityButton" onClick={() => {handleDuplicate("Rejected")}}>
                Reject Duplicate
            </button>
        </Modal>

        {projectId && <>
            <button className='grayButton' onClick={()=> setFilterOpen(!filterOpen)}>Filter</button>
            {filtered && <button className='grayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
            {!filtered && <button className='lightGrayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
            {/*<button onClick={()=> setSortOpen(true)}>Sort</button>*/}
            {/*<button onClick={()=> clearSort()}>Clear Sort</button>*/}
            {filterOpen ? (
                <ul className="menu fortySixPercentMenu" ref={filterRef}>
                    <li className="menu-item">
                        Status <br />
                        <select  name="status" defaultValue={duplicateFilteredData.status}
                                 onChange={(e) =>
                                     setDupeFilteredData( {...duplicateFilteredData, status: e.target.value})}>
                            <option key="Open" value="Open">Open</option>
                            <option key="Approved" value="Approved">Approved</option>
                            <option key="Rejected" value="Rejected">Rejected</option>

                        </select>
                    </li>

                    <li className="menu-item">
                        Phase
                        <input type="text" defaultValue={duplicateFilteredData.Phase} name="Phase" placeholder="Phase"
                               onChange={(e) =>
                                   setDupeFilteredData( {...duplicateFilteredData, phase: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Requested On
                        <input type="date" defaultValue={duplicateFilteredData.assigned_date}
                               onChange={(e) =>
                                   setDupeFilteredData({...duplicateFilteredData, assigned_date: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Seller Request ID
                        <input type="text" defaultValue={duplicateFilteredData.seller_request_id} name="seller_request_id" placeholder="Seller Request ID"
                               onChange={(e) =>
                                   setDupeFilteredData( {...duplicateFilteredData, seller_request_id: e.target.value})}>
                        </input>
                    </li>

                    {/*<li className="menu-item">*/}
                    {/*    Buyer Request ID*/}
                    {/*    <input type="text" name="buyer_request_id" defaultValue={duplicateFilteredData.buyer_request_id} placeholder="Buyer Request ID"*/}
                    {/*           onChange={(e) =>*/}
                    {/*               setDupeFilteredData( {...duplicateFilteredData, buyer_request_id: e.target.value})}>*/}
                    {/*    </input>*/}
                    {/*</li>*/}

                    <li className="menu-item">
                        Category <br />
                        <select  name="category_id" defaultValue={duplicateFilteredData.category_id}
                                 onChange={(e) =>
                                     setDupeFilteredData( {...duplicateFilteredData, category_id: e.target.value})}>
                            <option key={0} value={0}>Select a Category</option>
                            {categories && categories.map((category,i)=> {
                                return (
                                    <option key={category.id} value={category.id}>{category.category_name}</option>
                                )
                            })}
                        </select>
                    </li>
                    <li className="menu-item">
                        Request Name
                        <input type="text" name="data_requested" defaultValue={duplicateFilteredData.data_requested} placeholder="Date Requested"
                               onChange={(e) =>
                                   setDupeFilteredData( {...duplicateFilteredData, data_requested: e.target.value})}>
                        </input>
                    </li>
                    <li className="menu-item">
                        Request Details
                        <input type="text" defaultValue={duplicateFilteredData.details} name="filterRequestId" placeholder="Details"
                               onChange={(e) =>
                                   setDupeFilteredData( {...duplicateFilteredData, details: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        {BUYER_PREFERRED_FILE_TYPE} <br />
                        <select  name="file_type_id" defaultValue={duplicateFilteredData.file_type_id}
                                 onChange={(e) =>
                                     setDupeFilteredData( {...duplicateFilteredData, file_type_id: e.target.value})}>
                            <option key={0} value={0}>Select a File Type</option>
                            {fileTypes && fileTypes.map((fileType,i)=> {
                                return (
                                    <option key={fileType.id} value={fileType.id}>{fileType.file_type}</option>
                                )
                            })}
                        </select>
                    </li>


                    <li>
                        <button onClick={()=> handleDuplicateQueueFetch()}>Apply</button>
                    </li>
                </ul>
            ) : null}

            <table key="dupeQueueTable" className="sellerListTable">
                <tbody>
                {duplicatesQueue.length > 0 && (duplicatesQueue).map((duplicate,i)=>{
                    const status = duplicate.status == true ? "Approved" : "Unapproved";
                    const retained = duplicate.retained;
                    const deleted = duplicate.deleted;
                    let show_buttons = true;
                    if (duplicate.status == 'Approved' || duplicate.status == 'Rejected') {
                        show_buttons = false
                    }
                    return (<>
                        <br />
                        <br />
                        <br />
                        <br />
                        <tr className="outline">
                            <td>
                                {show_buttons && <button className="greenButton" onClick={()=> { openApproveModal(duplicate.id) }}>Approve</button>}
                                {show_buttons && <button className="redButton" onClick={()=> { openRejectModal(duplicate.id) }}>Reject</button>}
                            </td>
                            <td className="outline"><b>Phase</b></td>
                            <td className="outline"><b>Requested On</b></td>
                            <td className="outline"><b>{SELLER_REQUEST_ID}</b></td>
                            <td className="outline"><b>{BUYER_REQUEST_ID}</b></td>
                            <td className="outline"><b>Category</b></td>
                            <td className="outline"><b>Request Name</b></td>
                            <td className="outline"><b>Request Details</b></td>
                            <td className="outline"><b>{DATE_RANGE}</b></td>
                            <td className="outline"><b>Preferred File Type</b></td>
                            <td className="outline"><b>Status</b></td>
                            <td className="outline"><b>Requestor</b></td>
                        </tr>
                        <tr key={"deleted"+duplicate.id}>
                            <td className="outline" style={redTD}>Deleted</td>
                            <td className="outline" style={redTD}>{deleted.batch}</td>
                            <td className="outline" style={redTD}>{deleted.assigned_date}</td>
                            <td className="outline" style={redTD}>{deleted.seller_request_id}</td>
                            <td className="outline" style={redTD}>{deleted.buyer_request_ids}</td>
                            <td className="outline" style={redTD}>{deleted.category}</td>
                            <td className="outline" style={redTD}>{deleted.data_requested}</td>
                            <td className="outline" style={redTD}>{deleted.details}</td>
                            <td className="outline" style={redTD}>{deleted.parameters}</td>
                            <td className="outline" style={redTD}>{deleted.file_type}</td>
                            <td className="outline" style={redTD}>{status}</td>
                            <td className="outline" style={redTD}>{duplicate.user}</td>
                        </tr>

                        <tr key={"retainted"+duplicate.id} >
                            <td className="outline" style={greenTD}>Retained</td>
                            <td className="outline" style={greenTD}>{retained.batch}</td>
                            <td className="outline" style={greenTD}>{retained.assigned_date}</td>
                            <td className="outline" style={greenTD}>{retained.seller_request_id}</td>
                            <td className="outline" style={greenTD}>{retained.buyer_request_ids}</td>
                            <td className="outline" style={greenTD}>{retained.category}</td>
                            <td className="outline" style={greenTD}>{retained.data_requested}</td>
                            <td className="outline" style={greenTD}>{retained.details}</td>
                            <td className="outline" style={greenTD}>{retained.parameters}</td>
                            <td className="outline" style={greenTD}>{retained.file_type}</td>
                            <td className="outline" style={greenTD}>{status}</td>
                            <td className="outline" style={greenTD}>{duplicate.user}</td>
                        </tr>
                    </>)
                })}
                </tbody>
            </table>
        </>}
    </>
}