import axios from "axios";
import * as CONSTANTS from "../Constants";
import {EMAIL_ERROR_MESSAGE, INVALID_ENTITY_TYPE, THIRD_PARTY_ERROR_MESSAGE} from "../Constants";
const token = localStorage.getItem("accessToken");

export function fetchThirdPartyAssignment(id, setThirdPartyAssignment, setError) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/project_entity_third_parties/'+ id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setThirdPartyAssignment(response.data.project_entity_third_party);
            setError(false);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function fetchThirdPartyCategories(setBuyerCategories, setMultiples, setError) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/buyer_advisor_categories/'+ localStorage.getItem("projectId"),
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setBuyerCategories(response.data.categories.categories);
            setMultiples(response.data.categories.multiples)
            setError(false);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function fetchThirdPartyAssignments(setThirdPartyAssignments, reset) {
    let params = '';
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/project_entity_third_parties?'+ params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setThirdPartyAssignments(response.data.project_entity_third_parties);
            if (reset) { reset(); }
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function saveThirdPartyAssignments(data, setError, reset, setIsOpen, action, setThirdPartyUpdate) {
    if (data.project_id === 0 || data.project_id === "0") { setError(CONSTANTS.PROJECT_ERROR_MESSAGE); return;}
    if (data.third_party_id === 0 || data.third_party_id === "0") { setError(CONSTANTS.THIRD_PARTY_ERROR_MESSAGE); return;}
    if (data.active === 'Select Active/Inactive' || data.active === '') { setError(CONSTANTS.ACTIVE_ERROR_MESSAGE); return;}
    if (data.entity_type === 0 || data.entity_type === "0") { setError(CONSTANTS.INVALID_ENTITY_TYPE); return;}
    // if (data.email_list === undefined) { setError(CONSTANTS.EMAIL_ERROR_MESSAGE); return;}

    data.active = data.active === 'true' || data.active === true;
    if (action === "POST") {
        if (data.project_id === 'Select a Project') { setError(CONSTANTS.PROJECT_ERROR_MESSAGE); return;}
        if (data.third_party_id === 'Select A Third Party') { setError(CONSTANTS.THIRD_PARTY_ERROR_MESSAGE); return;}
        data.project_id = parseInt(data.project_id);
        data.third_party_id = parseInt(data.third_party_id);
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/project_entity_third_parties', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                setError(false);
                reset();
                setIsOpen(false);
                setThirdPartyUpdate(true);
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    } else if (action === "PUT") {
        let category_id = null;
        if (data.category_id != null) {
            category_id = data.category_id.toString();
        }

        let category_list = null;
        if (data.category_list != null) {
            category_list = data.category_list.toString();
        }
        const json_data = {
            "active": data.active,
            "email_list": data.email_list,
            "category_id": category_id,
            "category_list": category_list
        }

        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/project_entity_third_parties/' + data.id,
            json_data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                setError(false);
                reset();
                setIsOpen(false);
                setThirdPartyUpdate(true);
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
        });
    }
}

