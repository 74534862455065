import axios from "axios";
import * as CONSTANTS from "../Constants";
import {BATCH_NOT_SET_ERROR} from "../Constants";
import {define_sorting} from "./Generic";

const token = localStorage.getItem("accessToken");
export function fetchProjects(filteredData, setProjects, setFilterOpen, sorting, setSortOpen) {
    let params = "";
    if (filteredData) {
        if (filteredData.active && filteredData.active != 'Select Active/Inactive') {params += "active="+filteredData.active; }
        if (filteredData.code) {params += "&code="+filteredData.code; }
        if (filteredData.entity_name) {params += "&entity_name="+filteredData.entity_name; }
        if (filteredData.entity_type_id && filteredData.entity_type_id != 'Entity Type') {params += "&entity_type_id="+filteredData.entity_type_id; }
        if (filteredData.seller_entity_id && filteredData.seller_entity_id != 'Entity Type') {params += "&seller_entity_id="+filteredData.seller_entity_id; }
    }
    params = define_sorting(params, sorting);
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/projects?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setProjects(response['data']['projects']);
            setFilterOpen(false);
            setSortOpen(false);
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function fetchProject(projectId, setProject) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/projects/'+ projectId,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setProject(response['data']['project']);
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function saveProject(data, setError, filteredData, setProjects, setFilterOpen, setModalIsOpen, action) {
    console.log(data);
    if (data['project_name'] === '') { setError("You must enter a Project Name"); return;}
    if (data['seller_entity_id'] === 0 || data['seller_entity_id'] === "0") { setError("You must select a Seller Entity"); return;}
    if (data['buyer_entity_id'] === 0 || data['buyer_entity_id'] === "0") { setError("You must select a Buyer Entity"); return;}
    data['active'] = data['active'] === 'true' || data['active'] === true;
    data['batch'] = parseInt(data['batch']);
    data['buyer_entity_id'] = parseInt(data['buyer_entity_id']);
    data['seller_entity_id'] = parseInt(data['seller_entity_id']);

    if (action === "POST") {
        data['batch'] = 0;
        if (data['seller_entity_id'] === 'Select Seller') { setError(CONSTANTS.SELLER_ERROR_MESSAGE); return;}
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/projects', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                fetchProjects(filteredData, setProjects, setFilterOpen);
                setModalIsOpen(false);

            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
        });
    } else {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/projects/' + data['id'], data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                fetchProjects(filteredData, setProjects, setFilterOpen);
                setModalIsOpen(false);
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
}