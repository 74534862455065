import {React, useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {DATE_RANGE, PRIORITY, REFRESH_RATE, RESPOND_BUTTON_TEXT} from "../../Constants";
import SellerListFilter from "../../components/Filters/SellerFilter";
import {fetchSellerList} from "../../Functions/SellersList";
import ResponseViewModal from "../../components/Modals/ResponseViewModal";
import DuplicateSellerRequest from "../../components/Modals/DuplicateSellerRequest";
import AddResponseModal from "../../components/Modals/AddResponseModal";

export default function SellerList({updateSellersListData}) {
    const { register: registerUpdate, handleSubmit: handleSubmitUpdate , reset:resetUpdate} = useForm();
    const[, setSellerRepsonseId] = useState();
    const[dupeSellerRequestId, setDupeSellerRequestId] = useState();
    const[error, setError] = useState();
    const[files, setFiles] = useState([]);
    const[filterOpen, setFilterOpen] = useState(false);
    const[filtered, setFiltered] = useState(false);
    const[openDuplicateModalValue, setOpenDuplicateModal] = useState(false);
    const[projectId, ] = useState(localStorage.getItem('projectId'));
    const[ResponseModalOpen, setOpenResponseModal] = useState(false);
    const[ResponseViewModalOpen, setOpenViewResponseModal] = useState(false);
    const[sellerListRowData, setsellerListRowData] = useState([]);
    const[sellerRequest, setSellerRequest] = useState([]);
    const[sellerResponseRequestId, setSellerResponseRequestId] = useState('');
    const[sellerResponseViewData, setSellerResponseViewData] = useState([]);
    const[sortOpen, setSortOpen] = useState();
    const[sorted, setSorted] = useState(false);
    const[updateListData, setUpdateListData] = useState(false);
    const[sellerFilteredData, setSellerFilteredData] = useState({
        id: "",
        assigned_date: "",
        seller_request_id: "",
        deliverable_phase: "",
        data_requested: "",
        parameters: "",
        details: "",
        description: "",
        status_id: "",
        batch: "",
        category_id: "",
        priority: "",
        batch_enabled: true,
        deleted: false,
    });
    const[sellerListSorting, setSellerListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
        sort_ten: "",
        sort_ten_direction:"asc",
    });
    const sort_array = [
        {id: "batch", name: "Phase"},
        {id: "priority", name: "Priority"},
        {id: "assigned_date", name: "Requested On"},
        {id: "seller_request_id", name: "Seller Req ID"},
        {id: "category.category_name", name: "Category"},
        {id: "data_requested", name: "Request Name"},
        {id: "details", name: "Request Details"},
        {id: "parameters", name: "Request Scope"},
        {id: "file_type.file_type", name: "Preferred File Type"},
        {id: "status.status", name: "Status"},
    ];
    const filterRef = useRef(null);
    const sortRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            // Close the modal here
            setFilterOpen(false);
        }
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            // Close the modal here
            setSortOpen(false);
        }
    };

    const handleSellersListFetch = (e) => {
        setError(null);
        for (const key in sellerFilteredData) {
            if (key == 'batch_enabled' || key == 'deleted') {
                continue;
            }
            if (sellerFilteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        for (const key in sellerListSorting) {
            if (sellerListSorting[key] !== "" && sellerListSorting[key] !== 'asc') {
                setSorted(true);
                break;
            }
        }
        fetchSellerList(projectId, sellerFilteredData, setsellerListRowData, setFilterOpen, sellerListSorting, setSortOpen);
        setUpdateListData(false);
    }

    useEffect(() => {
            const intervalId = setInterval(() => {
                setUpdateListData(true);
            }, REFRESH_RATE);
            // Clear interval on component unmount
            return () => clearInterval(intervalId); }
        , []);

    useEffect(handleSellersListFetch, [updateListData]);


    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const openSort = () => { setSortOpen(!sortOpen); };

    function clearSellerFilter() {
        const emptyFilterData = Object.keys(sellerFilteredData).reduce((acc, key) => {
            if (key === "batch_enabled") {
                acc[key] = true;
            } else if (key === "deleted") {
                acc[key] = false;
            } else {
                acc[key] = "";
            }
            return acc;
        }, {});
        setSellerFilteredData(emptyFilterData);
        setFiltered(false);
        setUpdateListData(true);
    }

    function clearSort() {
        const emptySortData = Object.keys(sellerListSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setSellerListSorting(emptySortData);
        setSorted(false);
        setUpdateListData(true);
    }

    function openResponseModal(seller_request_id, seller_response_id, action, data) {
        setFiles([]);
        setSellerRequest(data);
        setOpenViewResponseModal(false);
        setSellerResponseRequestId(seller_request_id);
        setSellerRepsonseId(seller_response_id);
        setOpenResponseModal(!ResponseModalOpen);
    }

    function openDuplicateModal(sellerRequestId) {
        setDupeSellerRequestId(sellerRequestId);
        setOpenDuplicateModal(true);
    }

    return (
        <div>
            <AddResponseModal
                ResponseModalOpen={ResponseModalOpen}
                setOpenResponseModal={setOpenResponseModal}
                setFiles={setFiles}
                sellerRequest={sellerRequest}
                sellerResponseRequestId={sellerResponseRequestId}
                files={files}
                setUpdateList={setUpdateListData}
            />

            <DuplicateSellerRequest
                projectId={projectId}
                sellerRequestId={dupeSellerRequestId}
                modalIsOpen={openDuplicateModalValue}
                setModalIsOpen={setOpenDuplicateModal}
                fetchSellerList={fetchSellerList}
                sellerPageFilteredData={sellerFilteredData}
                setsellerListRowData={setsellerListRowData}
                setSellerPageFilterOpen={setFilterOpen}
                setSortOpen={setSortOpen}
            />
            {projectId && <h2><div style={{color:"#3178C6"}}>Project {localStorage.getItem('projectname')}</div><div style={{color:"gray"}}>Seller Request List</div></h2>}
            {!projectId && <div>
                <h2><div style={{color:"red"}}>Project Not Selected</div>
                    <div style={{color:"gray"}}>Seller Request List</div>
                </h2>
                <em style={{color:"red"}}>Please select a project on the Home page</em>
            </div>}

            {error && <h1 className="userError">{error}</h1>}
            <div>
                {projectId && <>
                    <button className="grayButton" onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
                    {filtered && <button className="grayButton" onClick={()=>clearSellerFilter()}>Clear Filter</button>}
                    {!filtered && <button className="lightGrayButton" onClick={()=>clearSellerFilter()}>Clear Filter</button>}

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="grayButton" onClick={openSort}>Sort</button>
                    {sorted && <button className="grayButton" onClick={()=>clearSort()}>Clear Sort</button>}
                    {!sorted && <button className="lightGrayButton" onClick={()=>clearSort()}>Clear Sort</button>}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>}
                <SellerListFilter filterOpen={filterOpen} setSellerFilteredData={setSellerFilteredData}
                                  sellerFilteredData={sellerFilteredData} handleSellersListFetch={handleSellersListFetch}
                                  filterRef={filterRef}
                />
                {sortOpen ? (
                    <ul className="menu fiftyOnePercentMenu" ref={sortRef}>
                        <li className="menu-item">
                            Sort By
                            <select name="id" defaultValue={sellerListSorting.sort_one}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_one: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>

                            <select name="sort_one_direction" defaultValue={sellerListSorting.sort_one_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_one_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_two" defaultValue={sellerListSorting.sort_two}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_two: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_two_direction" defaultValue={sellerListSorting.sort_two_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_two_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_three" defaultValue={sellerListSorting.sort_three}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_three: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_three_direction" defaultValue={sellerListSorting.sort_three_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_three_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_four" defaultValue={sellerListSorting.sort_four}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_four: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_four_direction" defaultValue={sellerListSorting.sort_four_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_four_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_five" defaultValue={sellerListSorting.sort_five}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_five: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_five_direction" defaultValue={sellerListSorting.sort_five_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_five_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_six" defaultValue={sellerListSorting.sort_six}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_six: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_six_direction" defaultValue={sellerListSorting.sort_six_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_six_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_seven" defaultValue={sellerListSorting.sort_seven}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_seven: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_seven_direction" defaultValue={sellerListSorting.sort_seven_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_seven_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_eight" defaultValue={sellerListSorting.sort_eight}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_eight: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_eight_direction" defaultValue={sellerListSorting.sort_eight_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_eight_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_nine" defaultValue={sellerListSorting.sort_nine}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_nine: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_nine_direction" defaultValue={sellerListSorting.sort_nine_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_nine_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_ten" defaultValue={sellerListSorting.sort_ten}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_ten: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_ten_direction" defaultValue={sellerListSorting.sort_ten_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_ten_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li>
                            <button onClick={handleSellersListFetch}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>
            {projectId &&
                <table className="sellerListTable">
                    <thead>
                    <tr className="outline">
                        <th className="outline">Phase</th>
                        <th className="outline">Priority</th>
                        <th className="outline">Requested On</th>
                        <th className="outline">Seller Req ID</th>
                        <th className="outline">Category</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Request Details</th>
                        <th className="outline">{DATE_RANGE}</th>
                        <th className="outline">Preferred File Type</th>
                        <th className="outline">Status</th>
                        <th className="outline" colSpan={3}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {sellerListRowData && sellerListRowData.map((sellerListData, i) => {
                        let showDuplicate = true;
                        if (sellerListData.status == "Closed" || sellerListData.show_duplicate_button == false) {
                            showDuplicate = false;
                        }

                        return (
                            <tr key={sellerListData.id} className="outline">
                                <td className="outline">{sellerListData.batch}</td>
                                <td className="outline">{PRIORITY[sellerListData.priority]}</td>
                                <td className="outline">{sellerListData.assigned_date}</td>
                                <td className="outline">{sellerListData.seller_request_id}</td>
                                <td className="outline">{sellerListData.category}</td>
                                <td className="outline">{sellerListData.data_requested}</td>
                                <td className="outline">{sellerListData.details}</td>
                                <td className="outline">{sellerListData.parameters}</td>
                                <td className="outline">{sellerListData.file_format}</td>
                                <td className="outline">{sellerListData.status}</td>
                                {sellerListData.response_count == 1 && <td>
                                    <button className="grayButton" onClick={() => {
                                        openResponseModal(sellerListData.id, '', "POST", sellerListData)
                                    }}>
                                        View {sellerListData.response_count} Response
                                    </button>
                                </td>}
                                {sellerListData.response_count > 1 && <td>
                                    <button className="grayButton" onClick={() => {
                                        openResponseModal(sellerListData.id, '', "POST", sellerListData)
                                    }}>
                                        View {sellerListData.response_count} Responses
                                    </button>
                                </td>}
                                {sellerListData.response_count === 0 && <td></td>}
                                <td>
                                    {showDuplicate && <button className="grayButton" onClick={() => {
                                        openDuplicateModal(sellerListData.id)
                                    }}>Report Duplicate</button>}
                                </td>
                                <td>
                                    <button className="blueButton" onClick={() => {
                                        openResponseModal(sellerListData.id, '', "POST", sellerListData)
                                    }}>{RESPOND_BUTTON_TEXT}</button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            }

        </div>
    );
}