import axios from "axios";

const token = localStorage.getItem("accessToken");

export default function fetchCategories(setCategories) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/categories',
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setCategories(response['data']['categories']);
        })
        .catch(function (error) {
            console.log(error);
        })
}