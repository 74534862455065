import {React, useEffect, useRef, useState} from "react";
import {fetchAuditlog} from "../../Functions/AuditlogFunctions";
import {calculate_date_time} from "../../Functions/DateTimeFunctions";
import {SortDropdown} from "../../Functions/Generic";

export default function Auditlog() {
    const[auditlogs, setAuditlogs] = useState([]);
    const[filterOpen, setFilterOpen] = useState(false);
    const[filtered, setFiltered] = useState(false);
    const[sortOpen, setSortOpen] = useState();
    const[sorted, setSorted] = useState(false);
    const[auditlogFilteredData, setAuditlogFilteredData] = useState({
        id: "",
        timestamp: "",
        log_entry: ""
    });
    const[auditlogSorting, setAuditlogSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
    });
    const sort_array = [
        {id: "id", name: "ID"},
        {id: "timestamp", name:"Created"},
        {id: "log_entry", name:"Log Entry"},
    ];
    const[updateAuditlogData, setUpdateAuditlogData] = useState();
    const filterRef = useRef(null);
    const sortRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            // Close the modal here
            setFilterOpen(false);
        }
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            // Close the modal here
            setSortOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleFetchAuditLog = (e) => {
        for (const key in auditlogFilteredData) {
            if (auditlogFilteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        for (const key in auditlogSorting) {
            if (auditlogSorting[key] !== "" && auditlogSorting[key] !== 'asc') {
                setSorted(true);
                break;
            }
        }
        fetchAuditlog(setAuditlogs, auditlogFilteredData, auditlogSorting, setFilterOpen, setSortOpen);
        setUpdateAuditlogData(false);
    }

    useEffect(() => {handleFetchAuditLog();}, [updateAuditlogData]);

    function clearFilter() {
        const emptyFilterData = Object.keys(auditlogFilteredData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setAuditlogFilteredData(emptyFilterData);
        setFiltered(false);
        setUpdateAuditlogData(true);
    }

    function clearSort() {
        const emptySortingData = Object.keys(auditlogSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setAuditlogSorting(emptySortingData)
        setSorted(false);
        setUpdateAuditlogData(true);
    }

    const openFilter = () => {
        setFilterOpen(!filterOpen);
    };

    const openSort = () => { setSortOpen(!sortOpen); };


    return (
        <div>
            <h2>Audit Log</h2>
            <br/>
            <div>
                <button className='grayButton' onClick={openFilter}>Filter</button>
                {filtered && <button className='grayButton' onClick={()=>clearFilter()}>Clear Filter</button>}
                {!filtered && <button className='lightGrayButton' onClick={()=>clearFilter()}>Clear Filter</button>}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <button className='grayButton' onClick={openSort}>Sort</button>
                {sorted && <button className='grayButton' onClick={()=>clearSort()}>Clear Sort</button>}
                {!sorted && <button className='lightGrayButton' onClick={()=>clearSort()}>Clear Sort</button>}
                {filterOpen ? (
                    <ul className="menu fortyPercentMenu" ref={filterRef}>
                        <li className="menu-item">
                            Id
                            <input type="text" defaultValue={auditlogFilteredData.id} name="auditlog_id" placeholder="ID"
                                   onChange={(e) =>
                                       setAuditlogFilteredData( {...auditlogFilteredData, id: e.target.value})}>
                            </input>
                        </li>

                        <li className="menu-item">
                            Created
                            <input type="date" defaultValue={auditlogFilteredData.timestamp}
                                   onChange={(e) =>
                                       setAuditlogFilteredData({...auditlogFilteredData, timestamp: e.target.value})}>
                            </input>
                        </li>

                        <li className="menu-item">
                            Log Entry
                            <input type="text" name="filterRequestName" defaultValue={auditlogFilteredData.log_entry} placeholder="Log Entry"
                                   onChange={(e) =>
                                       setAuditlogFilteredData( {...auditlogFilteredData, log_entry: e.target.value})}>
                            </input>
                        </li>
                        <li>
                            <button onClick={() => handleFetchAuditLog()}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>

            {sortOpen && (
                <ul className="menu fiftyOnePercentMenu" ref={sortRef}>
                    {['sort_one', 'sort_two', 'sort_three'].map((sortKey, index) => (
                        <SortDropdown key={index} sortKey={sortKey}
                                      sortDirectionKey={`${sortKey}_direction`}
                                      sortingState={auditlogSorting}
                                      setSortingState={setAuditlogSorting}
                                      sortArray={sort_array}
                        />
                    ))}
                    <li> <button onClick={handleFetchAuditLog}>Apply</button> </li> <
                /ul>
            )}
            <table className="sellerListTable">
                <thead>
                <tr className="outline">
                    <th className="outline">ID</th>
                    <th className="outline">Created</th>
                    <th className="outline">Log Entry</th>
                </tr>
                {/* Responses (Button), , Edit (Button).*/}
                </thead>
                <tbody>
                {auditlogs.length > 0 && (auditlogs).map((auditlog,i)=>{
                    const timestamp = calculate_date_time(auditlog.timestamp);
                    return (
                        <tr key={auditlog.id} className="outline">
                            <td className="outline">{auditlog.id}</td>
                            <td className="outline">{timestamp}</td>
                            <td className="outline">{auditlog.log_entry}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}