import React, {useEffect, useRef, useState} from "react";
import Modal from "react-modal";
import {useForm} from "react-hook-form";
import {customStyles, DATE_RANGE, RESPOND_BUTTON_TEXT} from "../../Constants";
import {fetchResponse, fetchResponses, updateResponse} from '../../Functions/Responses'
import ReopenQueue from "./ReopenQueue";
import ResponseViewModal from "../../components/Modals/ResponseViewModal";
import {calculate_date_time} from "../../Functions/DateTimeFunctions";
import {downloadFile} from "../../Functions/Files";

export default function ResponseManagement() {
    const { register, handleSubmit , reset, setValue} = useForm();
    const[, setIsLoading] = useState();
    const[error, setError] = useState();
    const[filtered, setFiltered] = useState();
    const[filterOpen, setFilterOpen] = useState(false);
    const[filteredData, setFilteredData] = useState({
        seller_response_id: "",
        response_id: "",
        response_details: "",
        edited_response: "",
        response_type: "buyer",
        status: "Open",
    });
    const[modalOpen, setModalOpen] = useState(false);
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[response, setResponse] = useState([]);
    const[responses, setResponses] = useState([]);
    const[sellerResponseViewData, setSellerResponseViewData] = useState([]);
    const[sellerRequest, setSellerRequest] = useState([]);
    const[updatedResponseList, setUpdateResponseLists] = useState(false);
    const filterRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setFilterOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const hendleFetchResponses = (e) => {
        for (const key in filteredData) {
            if (key == 'response_type') {
                continue;
            }
            if (filteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        setError(null);
        fetchResponses(filteredData, setResponses, setFilterOpen, projectId);
        setUpdateResponseLists(false);
    };

    useEffect( hendleFetchResponses, [updatedResponseList]);

    function clearFilter() {
        const emptyFilterData = Object.keys(filteredData).reduce((acc, key) => {
            if (key === "response_type") {
                acc[key] = 'buyer';
            } else if (key === "status") {
                acc[key] = 'Open';
            } else {
                acc[key] = "";
            }
            return acc;
        }, {});
        setFilteredData(emptyFilterData);
        setUpdateResponseLists(true);
        setFiltered(false);
    }

    const openResponseModal = (responseId, seller_request, responses) => {
        setSellerResponseViewData(responses);
        setSellerRequest(seller_request);
        setModalOpen(true);
        fetchResponse(responseId, reset, setResponse, setError);
    };

    const handleUpdateResponse = (data) => {
        updateResponse(data, setError, [], setIsLoading, setModalOpen);
        setUpdateResponseLists(true)
        setModalOpen(false);
    }

    return (
        <div>
            {projectId && <h2><div style={{color:"#3178C6"}}>Project {localStorage.getItem('projectname')}</div><div style={{color:"gray"}}>Response Queue</div></h2>}
            {!projectId && <div>
                <h2><div style={{color:"red"}}>Project Not Selected</div>
                    <div style={{color:"gray"}}>Response Queue</div>
                </h2>
                <em style={{color:"red"}}>Please select a project on the Home page</em>
            </div>}
            {error && <h1 className="userError">{error}</h1>}
            <Modal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                style={customStyles}
                contentLabel="Buyer Response Management"
                ariaHideApp={false}
            >
                <button onClick={() => setModalOpen(false)}>close</button>
                {error && <h1 className="userError">{error}</h1>}
                <h1>Seller Request</h1>
                <table className="sellerListTable">
                    <thead>
                    <tr className="outline">
                        <th className="outline">Phase</th>
                        <th className="outline">Requested On</th>
                        <th className="outline">Seller Req ID</th>
                        <th className="outline">Category</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Request Details</th>
                        <th className="outline">{DATE_RANGE}</th>
                        <th className="outline">Preferred File Type</th>
                        <th className="outline">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sellerRequest &&
                            <tr key={sellerRequest.id} className="outline">
                                <td className="outline">{sellerRequest.batch}</td>
                                <td className="outline">{sellerRequest.assigned_date}</td>
                                <td className="outline">{sellerRequest.seller_request_id}</td>
                                <td className="outline">{sellerRequest.category}</td>
                                <td className="outline">{sellerRequest.data_requested}</td>
                                <td className="outline">{sellerRequest.details}</td>
                                <td className="outline">{sellerRequest.parameters}</td>
                                <td className="outline">{sellerRequest.file_format}</td>
                                <td className="outline">{sellerRequest.status}</td>
                            </tr>
                    }
                    </tbody>
                </table>
                <br />
                <br />
                <br />
                <h1>Responses</h1>
                <table className='sellerListTable'>
                    <thead>
                    <tr>
                        <th className="outline">Response ID</th>
                        <th className="outline">Uploaded Date</th>
                        <th className="outline">Uploaded By</th>
                        <th className="outline">Comments</th>
                        <th className="outline">Document</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sellerResponseViewData && sellerResponseViewData.map((sellerResponses,i) => {
                        const documents = sellerResponses.docstorage;
                        let time_created = '';
                        if (sellerResponses.time_created !== '' && sellerResponses.time_created !== undefined) { time_created = calculate_date_time(sellerResponses.time_created); }

                        return (
                            <>
                                <tr key={sellerResponses.id}>
                                    <td className="outline">{sellerResponses.seller_response_id}</td>
                                    <td className="outline">{time_created}</td>
                                    <td className="outline">{sellerResponses.user}</td>
                                    <td className="outline">{sellerResponses.response_details}</td>
                                    <td className="outline">
                                        {documents && documents.map((document, )=>{
                                            const rand = Boolean(Math.round(Math.random()));
                                            const formStyle = {
                                                display: "contents"
                                            }
                                            return (<>
                                                <form onSubmit={handleSubmit(data => downloadFile(document.blob_name_raw, document.blob_name))}
                                                      style={formStyle}>
                                                    <input type="submit" value={document.blob_name} className="grayButton"/>
                                                </form>
                                            </>)
                                        })
                                        }
                                    </td>

                                </tr>
                            </>
                        )
                    })}
                    </tbody>
                </table>
                <br />
                <form onSubmit={handleSubmit(data => handleUpdateResponse(data))}>
                    {setValue('id', response.id)}
                    <input name="id" {...register('id')} hidden={true} />
                    <input name="id" defaultValue="buyer" {...register('response_type')} hidden={true} />
                    <label>{response.seller_response_id}</label>

                    <label>Comment</label>
                    {setValue('response_details', response.response_details)}
                    <input name="response_details" {...register('response_details')} disabled/>

                    <label>Edited Comment</label>
                    {setValue('edited_response', response.edited_response || response.response_details)}
                    <input name="edited_response" {...register('edited_response')} />

                    {/*<label>Approval</label>*/}
                    <select {...register('status')} hidden={true}>
                        <option value="Approved">Closed</option>
                    </select>
                    <input type="submit" value="Save Response" className="saveNewSellerRequestButton"/>
                </form>
            </Modal>

        {projectId && <>
            <div>
                <button className='grayButton' onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
                {filtered && <button className='grayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
                {!filtered && <button className='lightGrayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
                {filterOpen ? (
                    <ul className="menu fortySixPercentMenu" ref={filterRef}>
                        <li className="menu-item">
                            Seller Response ID
                            <input type="text" defaultValue={filteredData.seller_response_id} name="filterRequestId" placeholder="Seller Response ID"
                                   onChange={(e) =>
                                       setFilteredData( {...filteredData, seller_response_id: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Status <br />
                            <select name="filterlinkedRequests" defaultValue={filteredData.status} onChange={(e) =>
                                setFilteredData( {...filteredData, status: e.target.value})}>
                                <option value="Open">Open</option>
                                <option value="Approved">Closed</option>
                                {/*<option value="">All</option>*/}
                            </select>
                        </li>
                        <li className="menu-item">
                            Comment
                            <input type="text" defaultValue={filteredData.response_details} name="filterRequestId" placeholder="Comment"
                                   onChange={(e) =>
                                       setFilteredData( {...filteredData, response_details: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Edited Comment
                            <input type="text" defaultValue={filteredData.edited_response} name="filterRequestId" placeholder="Edited Comment"
                                   onChange={(e) =>
                                       setFilteredData( {...filteredData, edited_response: e.target.value})}>
                            </input>
                        </li>

                        <li>
                            <button onClick={hendleFetchResponses}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>
            <table className="sellerListTable">
                <thead>
                <tr className="outline">
                    <th className="outline">Response ID</th>
                    <th className="outline">User</th>
                    <th className="outline">Comment</th>
                    <th className="outline">Category</th>
                    <th className="outline">Request Name</th>
                    <th className="outline">Request Details</th>
                    <th className="outline">Request Scope</th>
                    <th className="outline">Preferred File Type</th>
                    <th className="outline">Seller Req Status</th>
                    <th className="outline"></th>
                </tr>
                </thead>
                <tbody>
                {responses.length > 0 && responses.map((response,i)=>{
                    return (
                        <tr key={response.id} className="outline">
                            <td className="outline">{response.seller_response_id}</td>
                            <td className="outline">{response.user}</td>
                            <td className="outline">{response.response_details}</td>
                            <td className="outline">{response.category}</td>
                            <td className="outline">{response.request_name}</td>
                            <td className="outline">{response.request_details}</td>
                            <td className="outline">{response.request_scope}</td>
                            <td className="outline">{response.file_type}</td>
                            <td className="outline">{response.seller_request_status}</td>
                            <td className="outline">

                                <button onClick={()=>
                                { openResponseModal(response.id, response.seller_request_data, response.seller_responses) }}>Edit Response
                                </button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <br />

            <ReopenQueue />
        </>}
    </div>)
}