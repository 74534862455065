import {React} from "react";

export const BUYER = 'buyer';
export const SELLER = 'seller';
export const ADMIN = 'admin';
export const ACTIVE_ERROR_MESSAGE = "Active/Inactive must be selected";
export const BATCH_NOT_SET_ERROR = "You must enter a starting batch";
export const BUYER_ERROR_MESSAGE = 'You must select a Buyer';
export const BUYER_PREFERRED_FILE_TYPE = 'Preferred File Type';
export const CODE_ERROR_MESSAGE = "Code can not be empty";
export const EMAIL_ERROR_MESSAGE = "Email cannot be blank";
export const FILE_LOG = "File Log";
export const FILE_TYPE_ERROR_MESSAGE = "File Type must be filled out";
export const INVALID_ENTITY_TYPE = "Invalid Entity Type";
export const NAME_ERROR = "Name must be filled out";
export const NEW_RESPONSE_FORM = "New Response Form";
export const OWNER_ERROR_MESSAGE = "You must select an Owner";
export const PROJECT_ERROR_MESSAGE = "You must select a Project";
export const RESPOND_BUTTON_TEXT = "Respond";
export const SELLER_ERROR_MESSAGE = "You must select a Seller";
export const SELECT_PROJECT = 'Select a Project';
export const THIRD_PARTY_ERROR_MESSAGE = "You must select a Third Party";
export const UNKNOWN_ERROR_MESSAGE = "An Unknown Error Occurred, Contact Administrator";
export const DATE_RANGE = "Request Scope";
export const DATE_RANGE_HELPER_TEXT = "Date range or other scope-limiting parameter (i.e. 2020-2024, TTM, Active)";
export const SELLER_REQUEST_ID= "Seller Req ID"
export const BUYER_REQUEST_ID = "Buyer Req ID"
export const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REFRESH_RATE = 60000;
export const statusSelect = [
    {"name": "Open"},
    {"name": "Partial"},
    {"name": "Complete"},
    {"name": "N/A"},
    {"name": "Cancelled" }
]

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '75%'
    },
};

export const PRIORITY= {
    1: "High",
    2: "Medium",
    3: "Low"
};