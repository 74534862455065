import axios from "axios";
const token = localStorage.getItem("accessToken");

export function fetchReopenQueue(filteredData, setReopenQueue, setFilterOpen) {
    if (filteredData.project_id === null) {
        setReopenQueue([]);
        return;
    }

    let params = "project_id=" + filteredData.project_id;
    if (filteredData.id != "") { params += "&id="+filteredData.id; }
    if (filteredData.seller_request_id != "") { params += "&seller_request_id="+filteredData.seller_request_id; }
    if (filteredData.reason != "") { params += "&reason="+filteredData.reason; }
    if (filteredData.status != "") { params += "&status="+filteredData.status; }
    if (filteredData.user_id != "") { params += "&user_id="+filteredData.user_id; }

    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/reopen_queue?'+params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setFilterOpen(false);
            setReopenQueue(response.data.reopen_queue);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function saveReopenQueue(data, setOpen) {
    console.log(data);
    axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/reopen_queue', data,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(data => {
            setOpen(false);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function updateReopenQueue(id, data, setUpdateReopenList) {
    axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/reopen_queue/'+id, data,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(data => {
            setUpdateReopenList(true);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}