import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import './navigation.css'
import { createBrowserRouter, Link, RouterProvider, Outlet} from 'react-router-dom';
import Auditlog from "../Auditlog/Auditlog";
import DataRequestManagement from "../DataRequestManagement/DataRequestManagement";
import FileLog from "../FileLog/FileLog";
import DuplicateQueue from "../DuplicateQueue/DuplicateQueue";
import BuyersList from "../BuyersList/BuyersList";
import EntityManagement from "../EntityManagement/EntityManagement";
import Profile from "../Profile/Profile";
import Projects from "../Projects/Projects";
import ResponseManagement from "../ResponseManagement/ResponseManagement";
import Roles from "../Role/Roles";
import SellerList from "../SellerList/SellerList";
import ThirdPartyAssignment from "../ThirdPartyAssignment/ThirdPartyAssignment";
import User from "../User/User";
import {ADMIN, BUYER, FILE_LOG, SELLER} from '../../Constants';
import { parsePermissions } from "../../Functions/PermissionsFunctions";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import EmailQueue from "../EmailQueue/EmailQueue";

export default function Navigation() {
    const [value, setValue] = React.useState(0);
    const [sellerRowData, setSellerRowData] = useState([]);
    const[buyerPermission, setBuyerPermission] = useState(false);
    const[adminPermission, setAdminPermission] = useState(false);
    const[sellerPermission, setSellerPermission] = useState(false);
    const[cantSeePermission, ] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [uploading, setUploading] = useState(false);

    window.addEventListener('storage', () => {
        const localStorageValue = localStorage.getItem('documentsUploading');
        if (localStorageValue > 0) {
            setUploading(true);
        } else {
            setUploading(false);
        }
    });

    const handleLogout = () => {
        setShowLogoutModal(true);
    };

    const cancelLogout = () => {
        setShowLogoutModal(false);
    };

    const confirmLogout = () => {
        setShowLogoutModal(false);
        localStorage.removeItem('projectId');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('permission');
        window.location.href = '/';

    }

    useEffect( () => {
        setAdminPermission(false);
        setSellerPermission(false);
        setBuyerPermission(false);
        parsePermissions(setBuyerPermission, setSellerPermission, setAdminPermission)
    }, [BUYER, SELLER, ADMIN]);

    const router = createBrowserRouter([
        {
            element: (
                <Box justifyContent="space-between" alignItems="center">
                    {uploading && <div style={{backgroundColor:"#3178C6", margin:"0px"}}>
                        <h1 style={{color:"white", margin:"0px"}} className="blink_me">&nbsp;Files Uploading&nbsp;</h1>
                    </div>}
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
                    <BottomNavigationAction
                        component={Link}
                        label="Home"
                        to='/'
                    />
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Projects"
                            to='/projectCreation'

                        />}
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Entities"
                            to='/entity'
                        />}
                        {cantSeePermission && <BottomNavigationAction
                            component={Link}
                            label="Roles/Permissions"
                            to='/rolesManagement'
                        />}
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Users"
                            to='/user'
                        />}
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Third Party Assignment"
                            to='/thirdPartyAssignment'
                        />}
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Request Management"
                            to='/dmo'
                        />}
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Response Queue"
                            to='/responseManagement'
                        /> }
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Duplicate Queue"
                            to='/seller_duplicates'
                        />}
                        {buyerPermission && <BottomNavigationAction
                            component={Link}
                            label="Buyer Requests"
                            to='/buyersListManagement'
                        /> }
                        {sellerPermission && <BottomNavigationAction
                            component={Link}
                            label="Seller Requests"
                            to='/sellerList'
                        />}
                        {buyerPermission && <BottomNavigationAction
                            component={Link}
                            label={FILE_LOG}
                            to='/filelog'
                        /> }
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Audit Log"
                            to='/auditlog'
                        /> }
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Email Queue"
                            to='/emailQueue'
                        /> }
                        <BottomNavigationAction
                            component="button"
                            label="Logout"
                            onClick={handleLogout}
                        />
                    </BottomNavigation>
                    <Outlet />
                    {showLogoutModal && (
                        <ConfirmationModal
                            message="Are you sure you want to logout?"
                            onConfirm={confirmLogout}
                            onCancel={cancelLogout}
                        />
                    )}
                </Box>
            ),
            children: [
                { path: "buyersListManagement", element: <BuyersList /> },
                { path: "", element: <Profile /> },
                { path: "dmo", element: <DataRequestManagement /> },
                { path: "entity", element: <EntityManagement /> },
                { path: "projectCreation", element: <Projects /> },
                { path: "sellerList", element: <SellerList /> },
                { path: "user", element: <User /> },
                { path: "thirdPartyAssignment", element: <ThirdPartyAssignment /> },
                { path: "rolesManagement", element: <Roles /> },
                { path: "responseManagement", element: <ResponseManagement /> },
                { path: "filelog", element: <FileLog /> },
                { path: "seller_duplicates", element: <DuplicateQueue /> },
                { path: "auditlog", element: <Auditlog /> },
                { path: "emailQueue", element: <EmailQueue /> },
            ],
        },
    ]);

    return (
        <div>
            <Box>
                <RouterProvider router={router} />
            </Box>
        </div>
    );
}