import axios from "axios";
import * as CONSTANTS from "../Constants";
import {define_sorting} from "./Generic";
const token = localStorage.getItem("accessToken");
export function fetchUser(userId, setUser) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/users/' + userId,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setUser(response.data.user);
        })
        .catch(function (error) {
            console.log(error);
        })
}
export function fetchUsers(setUserRowData, filteredData, setFilterOpen, sorting, setSortOpen) {
    let filtered = false;
    let params = "";
    if (filteredData) {
        if (filteredData.active && filteredData.active != 'Select Active/Inactive') {params += "active="+filteredData.active; filtered=true;}
        if (filteredData.user_name) {params += "&user_name="+filteredData.user_name; filtered=true;}
        if (filteredData.first_name) {params += "&first_name="+filteredData.first_name; filtered=filtered;}
        if (filteredData.nickname) {params += "&nickname="+filteredData.nickname; filtered=filtered;}
        if (filteredData.last_name) {params += "&last_name="+filteredData.last_name; filtered=filtered;}
        if (filteredData.suffix) {params += "&suffix="+filteredData.suffix; filtered=filtered;}
        if (filteredData.entity_id && filteredData.entity_id != 'Select Entity') {params += "&entity_id="+filteredData.entity_id; filtered=filtered;}
    }

    params = define_sorting(params, sorting);

    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/users?'+ params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setUserRowData(response['data']['users']);
            if(setFilterOpen) {setFilterOpen(false);}
            setSortOpen(false);
        })
        .catch(function (error) {
            // console.log(error);
        })
}

export function saveUserData(data, setError, setUpdateUserList, setIsOpen, reset, action, setIsDirty) {
    console.log(data);
    if (data['entity_id'] === 0 || data['entity_id'] === "0") { setError("Entity Must Be Selected"); return; }
    if (data['user_name'] === "") {setError("Email cannot be blank"); return;}
    if (!CONSTANTS.EMAIL.test(data['user_name'])) {setError("Email is not valid"); return;}
    if (data['first_name'] === "") {setError("First Name cannot be blank"); return;}
    if (data['last_name'] === "") {setError("Last Name cannot be blank"); return;}
    if (data['role'] === "0" || data['role'] === 0) { setError("Role Must Be Selected"); return; }
    data.active = data.active === 'true' || data.active === true ? 1 : 0;
    if (action === 'POST') {
        if (data['credential'] === "" || data['verify_credential'] === "") {
            setError("Password can not be empty/blank"); return;
        }
        if (data['credential'] !== data['verify_credential']) {
            setError("Passwords must match"); return;
        }
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/users', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                setIsOpen(false);
                setError(false);
                reset();
                setUpdateUserList(true);
                if(setIsDirty) { setIsDirty(false); }
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    setError(error.response.data.detail)
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    } else if (action == 'PUT') {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/users/' + data.id, data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                setUpdateUserList(true);
                setIsOpen(false);
                setError(false);
                reset();
                if(setIsDirty) { setIsDirty(false); }
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
}

export function importUsersSpreadsheet(setImportUserModalOpen, files, setError, setIsLoading, setUpdateUserList, setNotification) {
    files.map((file,i)=> {
        setIsLoading(true);
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/import_users/',
            { "file": file},
            { headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization" : `Bearer ${token}`
                }}
        ).then(data => {
            setNotification("Total Rows " + data.data.total_rows + " Failed Rows "+ data.data.error_count);
            setImportUserModalOpen(false);
            setIsLoading(false);
            setUpdateUserList(true);
        }).catch(function (error) {
            setError("Bad Data Check the Spreadsheet");
        });
    })
}