import axios from "axios";
import * as CONSTANTS from '../Constants'

const token = localStorage.getItem("accessToken");
const ADMIN = CONSTANTS.ADMIN;
const BUYER = CONSTANTS.BUYER;
const SELLER = CONSTANTS.SELLER;

export function fetchPermissions(permissionsFilterData, setPermissions, setFilterOpen){
    let params = "";
    if (permissionsFilterData.name !== "") { params += "&name="+ permissionsFilterData.name; }

    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/permissions?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            console.log(response.data.permissions);
            setPermissions(response.data.permissions);
            setFilterOpen(false);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function fetchPermission(id, setRole, setFilterOpen) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/permissions/'+ id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setRole(response['data']['permissions']);
            setFilterOpen(false);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function parsePermissions(setBuyerPermission, setSellerPermission, setAdminPermission) {
    const permissions = JSON.parse(localStorage.getItem('permission'));
    permissions.map((permission,i)=>{
        if (permission.name === BUYER) {
            setBuyerPermission(true);
        } else if (permission.name === SELLER) {
            setSellerPermission(true)
        } else if (permission.name === ADMIN) {
            setAdminPermission(true);
            setSellerPermission(true);
            setBuyerPermission(true);
        }
        return true;
    })
}

export function savePermissions(data, permissionsFilterData, setPermissions, setFilterOpen, setError, setIsOpen, reset, action) {
    if (action === "POST") {
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/permissions', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                fetchPermissions(permissionsFilterData, setPermissions, setFilterOpen);
                setIsOpen(false);
                setError(false);
                reset();
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    } else if (action === "PUT") {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/permissions/' + data.id, data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                fetchPermissions(permissionsFilterData, setPermissions, setFilterOpen);
                setIsOpen(false);
                setError(false);
                reset();
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
}