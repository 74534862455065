import axios from "axios";
import JSZip from "jszip";

const token = localStorage.getItem("accessToken");

async function zipFiles(files) {
    const zip = new JSZip();

    files.forEach((file, index) => {
        const path = file.path;
        const parts = path.split('/').filter(part => part !== "" && part !== ".");
        let currentFolder = zip;

        for (let i = 0; i < parts.length; i++) {
            if (i === parts.length - 1) {
                currentFolder.file(parts[i], file);
            } else {
                if (parts[i] !== "./") {
                    currentFolder = currentFolder.folder(parts[i]);
                }
            }
        }
    });

    const zipContent = await zip.generateAsync({ type: "blob", compression: "DEFLATE",
        compressionOptions: {
            level: 9
        }} );
    return zipContent;
}

export function fetchResponses(responseFilters, setResponses, setFilterOpen, projectId) {
    let params = "";
    if (projectId != "Select A Project") {params += "&project_id="+ projectId; }
    if (responseFilters.seller_request_id && responseFilters.seller_request_id !== "") {params += "&seller_request_id="+ responseFilters.seller_request_id; }
    if (responseFilters.seller_response_id && responseFilters.seller_response_id !== "") {params += "&seller_response_id="+ responseFilters.seller_response_id; }
    if (responseFilters.response_id && responseFilters.response_id !== "") { params += "&response_id="+ responseFilters.response_id; }
    if (responseFilters.response_details !== "") { params += "&response_details="+ responseFilters.response_details; }
    if (responseFilters.edited_response !== "") { params += "&edited_response="+ responseFilters.edited_response; }
    if (responseFilters.response_type && responseFilters.response_type !== "") { params += "&response_type="+ responseFilters.response_type; }
    if (responseFilters.status !== "") { params += "&status="+ responseFilters.status; }

    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_request_response?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setResponses(response.data.seller_request_response);
        })
        .catch(function (error) {
            console.log(error);
        })
    setFilterOpen(false)
}

export function fetchResponse(response_id, resetUpdate, setEditResponseData, setResponseError) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response/' + response_id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            resetUpdate();
            setEditResponseData(response.data.seller_request_response);
            setResponseError(false);
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function getResponseData(sellerResponseId, setSellerResponseViewData) {
    if (sellerResponseId == "") {
        return;
    }
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response_all/' + sellerResponseId,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setSellerResponseViewData(response.data.seller_request_response);
        })
        .catch(function (error) {
            console.log(error);
        })
}

// saveNewResponse.jsx
export async function saveNewResponse(data, setDocumentsUploading, documentsUploading, setResponseError, files,
                                      setFiles, setIsLoading, setError, setOpenResponseModal, reset, setUpdateList) {
    setResponseError("");

    if (data.response_type == 'seller' && data.status_id == "0") {
        setResponseError("You must select a status");
        return;
    }

    if (data.response_details === "") {
        setResponseError("Details must be filled out");
        return;
    }
    setOpenResponseModal(false);
    let zippedFiles = undefined;
    data['file_count'] = 0;
    if (files !== undefined && files.length > 0) {
        let documentLocalStorage = parseInt(localStorage.getItem("documentsUploading"));
        documentLocalStorage += 1;
        setDocumentsUploading(documentLocalStorage);
        setResponseError(false);

        setIsLoading(true);
        data['file_count'] = files ? files.length : 0;
        zippedFiles = await zipFiles(files);
    }
    reset();
    axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response', data, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }).then(data => {
        const response_id = data.data.seller_request_response.id;
        const seller_response_id = data.data.seller_request_response.seller_response_id;
        const fileName = seller_response_id + ".zip";
        if (zippedFiles !== undefined) {
            axios.post(process.env.REACT_APP_API_BASE_URL + '/api/docstorage/v1/' + fileName + '/' + response_id, {
                "file": zippedFiles
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": `Bearer ${token}`
                }
            }).then(data => {
                setIsLoading(false);
                let documentLocalStorage = parseInt(localStorage.getItem("documentsUploading"));
                documentLocalStorage -= 1;
                setDocumentsUploading(documentLocalStorage);
                setUpdateList(true);
            }).catch(function (error) {
                handleRequestError(error, setError);
            });
        }
        setUpdateList(true);
        setIsLoading(false);
    }).catch(function (error) {
        setUpdateList(true);
        handleRequestError(error, setError);
    });
}

function handleRequestError(error, setError) {
    if (error.response) {
        if (error.response.data.detail) {
            setError(error.response.data.detail);
        } else if (error.response.data.msg) {
            setError(error.response.data.msg);
        } else {
            setError("Unknown Error Occurred");
        }
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log('Error', error.message);
    }
    console.log(error.config);
}

export function updateResponse(data, setResponseError, files, setIsLoading, setOpenResponseModal,
                               handleSellersListFetch, setUpdateListData, setError) {
    if (data['response_details'] === '') {setResponseError("Details must be filled out"); return;}
    axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response/'+ data['id'], data,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(data => {
            setResponseError(false);
            const response_id = data.data.seller_request_response.id;
            files.map((file,i)=> {
                setIsLoading(true);
                const fileName = file.name;

                axios.post(process.env.REACT_APP_API_BASE_URL + '/api/docstorage/v1/'+ fileName +"/"+response_id,
                    { "file":file},
                    { headers: {
                            'Content-Type': 'multipart/form-data',
                            "Authorization" : `Bearer ${token}`
                        }}
                ).then(data => {
                    setIsLoading(false);
                    setUpdateListData(true);
                })
                    .catch(function (error) {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            if (error.response.data.detail) {
                                setError(error.response.data.detail);
                            } else if (error.response.data.msg) {
                                setError(error.response.data.msg);
                            } else {
                                setError("Unknown Error Occurred");
                            }
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            //setError(error.message);
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            })
            setIsLoading(false);
            setOpenResponseModal(false);
            if(handleSellersListFetch) { handleSellersListFetch(); }
        }).catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}
