import {React, useEffect, useState} from "react";
import fetchCategories from "../../Functions/CategoryFunctions";
import {fetchStatuses} from "../../Functions/StatusFunction";
import {DATE_RANGE} from "../../Constants";

export default function SellerListFilter({filterOpen, setSellerFilteredData, sellerFilteredData, handleSellersListFetch, filterRef})  {
    const[categories, setCategories] = useState([]);
    const[statuses, setStatuses] = useState([]);
    useEffect(() => fetchCategories(setCategories), []);
    useEffect(() => fetchStatuses(setStatuses), [])
    return <>
        {filterOpen ? (
            <ul className="menu fortyPercentMenu" ref={filterRef}>
                <li className="menu-item">
                    Phase
                    <input type="text" placeholder="Phase" defaultValue={sellerFilteredData.batch} onChange={(e) =>
                        setSellerFilteredData( {...sellerFilteredData, batch: e.target.value})}>
                    </input>
                </li>

                <li className="menu-item">
                    Priority <br/>
                    <select name="priority" onChange={(e) =>
                        setSellerFilteredData( {...sellerFilteredData, priority: e.target.value})}>
                        <option>Select Priority</option>
                        <option value={1}>High</option>
                        <option value={2}>Medium</option>
                        <option value={3}>Low</option>
                    </select>
                </li>

                <li className="menu-item">
                    Requested On
                    <input type="date" placeholder="Requested On" defaultValue={sellerFilteredData.assigned_date} onChange={(e) =>
                        setSellerFilteredData( {...sellerFilteredData, assigned_date: e.target.value})}>
                    </input>
                </li>

                <li className="menu-item">
                    Seller Request ID
                    <input type="text" placeholder="Seller Request ID" defaultValue={sellerFilteredData.seller_request_id} onChange={(e) =>
                        setSellerFilteredData( {...sellerFilteredData, seller_request_id: e.target.value})}>
                    </input>
                </li>
                <li className="menu-item">
                    Category <br/>
                    <select name="category_id" onChange={(e) =>setSellerFilteredData( {...sellerFilteredData, category_id: e.target.value})}>
                        <option>Select Category</option>
                        {categories.map((category,i) => {
                            return (
                                <option value={category.id}>{category.category_name}</option>
                            )
                        })}
                    </select>
                </li>


                <li className="menu-item">
                    Request Name
                    <input type="text" placeholder="Request Name" defaultValue={sellerFilteredData.data_requested} onChange={(e) =>
                        setSellerFilteredData( {...sellerFilteredData, data_requested: e.target.value})}>
                    </input>
                </li>
                <li className="menu-item">
                    Request Details
                    <input type="text" placeholder="Request Details" defaultValue={sellerFilteredData.details} onChange={(e) =>
                        setSellerFilteredData( {...sellerFilteredData, details: e.target.value})}>
                    </input>
                </li>

                <li className="menu-item">
                    {DATE_RANGE}
                    <input type="text" placeholder={DATE_RANGE} defaultValue={sellerFilteredData.parameters} onChange={(e) =>
                        setSellerFilteredData( {...sellerFilteredData, parameters: e.target.value})}>
                    </input>
                </li>


                <li className="menu-item">
                    <label>Request Status</label>
                    <select name="status" defaultValue={sellerFilteredData.status_id} onChange={(e) =>
                        setSellerFilteredData( {...sellerFilteredData, status_id: e.target.value})}>
                        <option value="">Filter By Status</option>
                        {statuses.map((status,i) => {
                            return (
                                <option value={status.id}>{status.status}</option>
                            )
                        })}
                    </select>
                </li>

                <li>
                    <button onClick={() => handleSellersListFetch()}>Apply</button>
                </li>
            </ul>
        ) : null}
    </>
}