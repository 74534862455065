import {React, useEffect, useRef, useState} from "react";
import {fetchDocuments} from "../../Functions/Files";
import {fetchUsers} from "../../Functions/UsersFunctions";
import {FILE_LOG} from "../../Constants";
import {calculate_date_time} from "../../Functions/DateTimeFunctions";
import {fetchBuyersList} from "../../Functions/BuyersList";

export default function FileLog() {
    const[documents, setDocuments] = useState([]);
    const[docstorageFilteredData, setDocstorageFilteredData] = useState({
        blob_name: "",
        blob_creation_time: "",
        blob_deleted: "",
        data_requested: "",
        seller_request_id: "",
        uploaded_by: "",
    });
    const[docstorageSorting, setDocstorageSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
    });
    const[filterOpen, setFilterOpen] = useState(false);
    const[filtered, setFiltered] = useState(false);
    const[sortOpen, setSortOpen] = useState();
    const[sorted, setSorted] = useState(false);
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[users, setUsers] = useState([]);
    const[updateDocumentData, setUpdateDocumentData] = useState();
    const fileName = 'documents';
    const sort_array = [
        {id: "seller_request_list.seller_request_id", name: "Seller Request ID"},
        {id: "seller_request_list.data_requested", name:"Seller Request Name"},
        {id: "blob_name", name:"File Name"},
        {id: "first_name", name:"Uploaded By"},
        {id: "blob_creation_time", name:"Uploaded"},
        {id: "blob_deleted", name:"Deleted"},
    ];
    const filterRef = useRef(null);
    const sortRef = useRef(null);


    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            // Close the modal here
            setFilterOpen(false);
        }
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            // Close the modal here
            setSortOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDocumentFetchClick = (e) => {
        for (const key in docstorageFilteredData) {
            if (docstorageFilteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        for (const key in docstorageSorting) {
            if (docstorageSorting[key] !== "" && docstorageSorting[key] !== 'asc') {
                setSorted(true);
                break;
            }
        }
        fetchDocuments(projectId, setDocuments, docstorageFilteredData, docstorageSorting, setFilterOpen, setSortOpen);
        setUpdateDocumentData(false);
    };

    useEffect(handleDocumentFetchClick, [updateDocumentData]);

    useEffect(() => fetchUsers(setUsers), [projectId]);

    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = 'Seller Request ID, Seller Request Name, File, Uploaded By, Uploaded, Deleted\r\n';
        let line = '';
        for (let i = 0; i < array.length; i++) {
            const blob_name = array[i]['blob_name'];
            const data_requested = array[i]['data_requested'];
            const seller_request_id = array[i]['seller_request_id'];
            const deleted = document.blob_deleted == true ? "Deleted" : "";
            const user_name = array[i]['user_name'];
            const blob_creation_time = array[i]['blob_creation_time'];

            if (line !== '') line += ',';
            line = seller_request_id +","+ data_requested + "," + blob_name + "," + user_name + "," + blob_creation_time + "," + deleted;
            str += line + '\r\n';
        }
        return str;
    };

   const downloadCSV = () => {
        const csvData = new Blob([convertToCSV(documents)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    function clearFilter() {
        const emptyFilterData = Object.keys(docstorageFilteredData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});

        setDocstorageFilteredData(emptyFilterData);
        setFiltered(false);
        setUpdateDocumentData(true);
    }

    function clearSort() {
        const emptySortingData = Object.keys(docstorageSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setDocstorageSorting(emptySortingData);
        setSorted(false);
        setUpdateDocumentData(true);
    }

    function sortAndFilter() {
        for (const key in docstorageFilteredData) {
            if (docstorageFilteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        for (const key in docstorageSorting) {
            if (docstorageSorting[key] !== "" && docstorageSorting[key] !== 'asc') {
                setSorted(true);
                break;
            }
        }
        handleDocumentFetchClick();
        setUpdateDocumentData(true);
    }

    return (<>
        {projectId && <h2><div style={{color:"#3178C6"}}>Project {localStorage.getItem('projectname')}</div><div style={{color:"gray"}}>{FILE_LOG}</div></h2>}
        {!projectId && <div>
            <h2><div style={{color:"red"}}>Project Not Selected</div>
                <div style={{color:"gray"}}>{FILE_LOG}</div>
            </h2>
            <em style={{color:"red"}}>Please select a project on the Home page</em>
        </div>}
        {projectId && <>
            <div>
                <button className="grayButton" onClick={()=> setFilterOpen(!filterOpen)}>Filter</button>
                {filtered && <button className="grayButton" onClick={()=>clearFilter()}>Clear Filter</button>}
                {!filtered && <button className="lightGrayButton" onClick={()=>clearFilter()}>Clear Filter</button>}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                <button className="grayButton" onClick={()=>setSortOpen(!sortOpen)}>Sort</button>
                {sorted && <button className="grayButton" onClick={()=>clearSort()}>Clear Sort</button>}
                {!sorted && <button className="lightGrayButton" onClick={()=>clearSort()}>Clear Sort</button>}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <button className="greenButton" onClick={downloadCSV}>Download CSV</button>

                {filterOpen ? (
                    <ul className="menu thirtyFourPercentMenu" ref={filterRef}>
                        <li className="menu-item">
                            Seller Request ID
                            <input type="text" placeholder="Seller Request ID" defaultValue={docstorageFilteredData.seller_request_id}
                                   onChange={(e) =>
                                       setDocstorageFilteredData({...docstorageFilteredData, seller_request_id: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Seller Request Name
                            <input type="text" placeholder="Seller Request Name" defaultValue={docstorageFilteredData.data_requested}
                                   onChange={(e) =>
                                       setDocstorageFilteredData({...docstorageFilteredData, data_requested: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            File
                            <input type="text" placeholder="File" defaultValue={docstorageFilteredData.blob_name}
                                   onChange={(e) =>
                                       setDocstorageFilteredData({...docstorageFilteredData, blob_name: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            <label>Uploaded By</label>
                            <select name="uploaded_by" defaultValue={docstorageFilteredData.uploaded_by}
                                    onChange={(e) =>
                                        setDocstorageFilteredData({...docstorageFilteredData, uploaded_by: e.target.value})}>
                                <option value="">Uploaded By</option>
                                {users && users.map((user,i)=> {
                                    return (
                                        <option key={user.id} value={user.id}>{user.full_name}</option>
                                    )
                                })}
                            </select>
                        </li>
                        <li className="menu-item">
                            Uploaded
                            <input type="date" placeholder="File" defaultValue={docstorageFilteredData.blob_creation_time}
                                   onChange={(e) =>
                                       setDocstorageFilteredData({...docstorageFilteredData, blob_creation_time: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            <label>Deleted?</label>
                            <select name="status" defaultValue={docstorageFilteredData.blob_deleted}
                                    onChange={(e) =>
                                        setDocstorageFilteredData({...docstorageFilteredData, blob_deleted: e.target.value})}>
                                <option value="">Filter By Deleted</option>
                                <option value={true}>True</option>
                                <option value={false}>False</option>
                            </select>
                        </li>
                        <li>
                            <button onClick={()=> sortAndFilter()}>Apply</button>
                        </li>
                    </ul>
                ) : null}

                {sortOpen ? (
                    <ul className="menu fortySixPercentMenu"  ref={sortRef}>
                        <li className="menu-item">
                            Sort By
                            <select name="id" defaultValue={docstorageSorting.sort_one}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_one: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>

                            <select name="sort_one_direction" defaultValue={docstorageSorting.sort_one_direction}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_one_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_two" defaultValue={docstorageSorting.sort_two}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_two: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_two_direction" defaultValue={docstorageSorting.sort_two_direction}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_two_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_three" defaultValue={docstorageSorting.sort_three}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_three: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_three_direction" defaultValue={docstorageSorting.sort_three_direction}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_three_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_four" defaultValue={docstorageSorting.sort_four}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_four: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_four_direction" defaultValue={docstorageSorting.sort_four_direction}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_four_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_five" defaultValue={docstorageSorting.sort_five}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_five: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_five_direction" defaultValue={docstorageSorting.sort_five_direction}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_five_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_six" defaultValue={docstorageSorting.sort_six}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_six: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_six_direction" defaultValue={docstorageSorting.sort_six_direction}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_six_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_seven" defaultValue={docstorageSorting.sort_seven}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_seven: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_seven_direction" defaultValue={docstorageSorting.sort_seven_direction}
                                    onChange={(e) =>
                                        setDocstorageSorting({...docstorageSorting, sort_seven_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li>
                            <button onClick={()=> sortAndFilter()}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>
            <table className="sellerListTable">
                <thead>
                <tr key="FileLogTrHead" className="outline">
                    <th className="outline">Seller Request ID</th>
                    <th className="outline">Seller Request Name</th>
                    <th className="outline">File</th>
                    <th className="outline">Uploaded By</th>
                    <th className="outline">Uploaded</th>
                    <th className="outline">Deleted</th>
                </tr>
                </thead>
                <tbody>
                {documents && documents.map((document,i)=>{
                    const deleted = document.blob_deleted == true ? "Deleted" : "";
                    const time_created = calculate_date_time(document.blob_creation_time);

                    return (
                        <tr key={document.id} className="outline">
                            <td className="outline">{document.seller_request_id}</td>
                            <td className="outline">{document.data_requested}</td>
                            <td className="outline">{document.blob_name}</td>
                            <td className="outline">{document.user_name}</td>
                            <td className="outline">{time_created}</td>
                            <td className="outline">{document.deleted}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>}
    </>)
}