import axios from "axios";
import {define_sorting} from "./Generic";

const token = localStorage.getItem("accessToken");

export function fetchDuplicateQueue(projectId, duplicateFilteredData, setDuplicateRowData, setFilterOpen) {
    let params = 'project_id=' + projectId;
    if (duplicateFilteredData['status'] !== "" ) { params += "&status=" + duplicateFilteredData['status']; }
    if (duplicateFilteredData['batch'] !== "" ) { params += "&batch=" + duplicateFilteredData['batch']; }
    if (duplicateFilteredData['seller_request_id'] !== "" ) { params += "&seller_request_id=" + duplicateFilteredData['seller_request_id']; }
    if (duplicateFilteredData['buyer_request_id'] !== "" ) { params += "&buyer_request_id=" + duplicateFilteredData['buyer_request_id']; }
    if (duplicateFilteredData['category_id'] !== "" ) { params += "&category_id=" + duplicateFilteredData['category_id']; }
    if (duplicateFilteredData['data_requested'] !== "" ) { params += "&data_requested=" + duplicateFilteredData['data_requested']; }
    if (duplicateFilteredData['details'] !== "" ) { params += "&details=" + duplicateFilteredData['details']; }
    if (duplicateFilteredData['parameters'] !== "" ) { params += "&parameters=" + duplicateFilteredData['parameters']; }
    if (duplicateFilteredData['file_type_id'] !== "" ) { params += "&file_type_id=" + duplicateFilteredData['file_type_id']; }
    if (duplicateFilteredData['assigned_date'] !== "")  {params += "&assigned_date=" + duplicateFilteredData.assigned_date; }
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_request_duplicate_queue?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setDuplicateRowData(response['data']['dupe_seller_request']);
            setFilterOpen(false);
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function fetchSellerList(projectId, duplicateFilteredData, setsellerListRowData, setFilterOpen, sorting, setSortOpen) {
    if (projectId === null) {
        setsellerListRowData([]);
        return;
    }

    let params = 'project_id=' + projectId;
    if (duplicateFilteredData['data_requested'] !== "" ) { params += "&data_requested=" + encodeURIComponent(duplicateFilteredData['data_requested']); }
    if (duplicateFilteredData['priority'] !== "" ) { params += "&priority=" + duplicateFilteredData['priority']; }
    if (duplicateFilteredData['assigned_date'] !== "" ) { params += "&assigned_date=" + encodeURIComponent(duplicateFilteredData['assigned_date']); }
    if (duplicateFilteredData['seller_request_id'] !== "" ) { params += "&seller_request_id=" + duplicateFilteredData['seller_request_id']; }
    if (duplicateFilteredData['parameters'] !== "" ) { params += "&parameters=" + encodeURIComponent(duplicateFilteredData['parameters']); }
    if (duplicateFilteredData['details'] !== "" ) { params += "&details=" + encodeURIComponent(duplicateFilteredData['details']); }
    if (duplicateFilteredData['status_id'] !== "" ) { params += "&status_id=" + duplicateFilteredData['status_id']; }
    if (duplicateFilteredData['batch'] !== "" ) { params += "&batch=" + duplicateFilteredData['batch']; }
    if (duplicateFilteredData['category_id'] !== "" ) { params += "&category_id=" + duplicateFilteredData['category_id']; }
    if (duplicateFilteredData['batch_enabled'] === true ) { params += "&batch_enabled=true" }
    if (duplicateFilteredData['deleted'] == false) { params += "&deleted=false"} else if (duplicateFilteredData['deleted'] == true) { params += "&deleted=true"}
    params = define_sorting(params, sorting);
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_request_list?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setsellerListRowData(response['data']['seller_request_list']);
            setFilterOpen(false);
            if (setSortOpen) { setSortOpen(false); }
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function fetchSellerRequest(id, setSellerRequest, setFilterOpen) {
    if (id == '' || id == null) { return; }
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_request_list/' + id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setSellerRequest(response['data']['seller_request_list']);
            if (setFilterOpen) { setFilterOpen(false); }
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function saveNewDuplicateQueue(data, setCloseModal, reset, fetchSellerList, sellerPageFilteredData,
                                      setsellerListRowData, setFilterOpen, sellerListSorting, setSortOpen) {
    axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_request_duplicate_queue', data,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setCloseModal(false);
            reset();
            fetchSellerList(data.project_id, sellerPageFilteredData, setsellerListRowData, setFilterOpen, sellerListSorting, setSortOpen);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function saveNewSellerData(data, setNewSellerRequestError, putBuyersRequest, setIsOpen, handleSellersListFetch) {
    data['project_id'] = localStorage.getItem('projectId');
    if (localStorage.getItem('projectId') === null) { setNewSellerRequestError("You must select a project"); return; }
    if (data['rowToLink'] === '' ) { setNewSellerRequestError("You must enter Data Requested"); return; }
    if (data['data_requested'] === '' ) { setNewSellerRequestError("You must enter Data Requested"); return; }
    if (data['priority'] === '0' ||  data['priority'] === 0) { setNewSellerRequestError("You must select a Priority"); return; }
    if (data['parameters'] === '' ) { setNewSellerRequestError("You must enter Parameters"); return; }
    if (data['details'] === '' ) { setNewSellerRequestError("You must enter Details"); return; }
    if (data['description'] === '' ) { setNewSellerRequestError("You must enter Description"); return; }
    if (data['batch'] === '' ) { setNewSellerRequestError("You must enter a Phase"); return; }
    if (data['category_id'] === "0") { setNewSellerRequestError("You must enter a Category"); return; }
    if (data['file_type_id'] === "0") { setNewSellerRequestError("You must enter a File Type"); return; }
    data['status'] = '';
    data['project_id'] = parseInt(data['project_id']);
    data['batch'] = parseInt(data['batch']);
    data['category_id'] = parseInt(data['category_id']);
    data['file_type_id'] = parseInt(data['file_type_id']);
    data['priority'] = parseInt(data['priority']);

    axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_request_list', data,
        { headers: { "Authorization" : `Bearer ${token}` }})
        .then(response => {
            putBuyersRequest(data['rowToLink'], response.data.seller_request_list.id)
            setNewSellerRequestError(false);
            setIsOpen(false);
            handleSellersListFetch();
        }).catch(function (error) {
            if (error.response) {
                setNewSellerRequestError("Data Not Saved");
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                setNewSellerRequestError("Data Not Saved");
                console.log(error.request);
            } else {
                setNewSellerRequestError("Data Not Saved");
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function updateDuplicateQueue(id, projectId, duplicateFilteredData, setDuplicateRowData, setFilterOpen, data) {
    axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_request_duplicate_queue/' + id, data,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            fetchDuplicateQueue(projectId, duplicateFilteredData, setDuplicateRowData, setFilterOpen)
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function updateSellerList(id, data, setUpdateSellerList, setShowModal) {
    axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_request_list/' + id, data,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setUpdateSellerList(true);
            if (setShowModal) { setShowModal(false); }
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

