import React, {useEffect, useRef, useState} from "react";
import {fetchEmailQueue} from "../../Functions/EmailQueueFunctions";
import {fetchEntities} from "../../Functions/EntityFunctions";
import {calculate_date_time} from "../../Functions/DateTimeFunctions";

export default function EmailQueue() {
    const[categories, setCategories]= useState({});
    const[emailQueues, setEmailQueue] = useState([]);
    const[entities, setEntities]=useState([]);
    const[entityListSorting, setEntityListSorting] = useState({
        sort_one: "code",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
    });
    const[fileTypes, setFileTypes] = useState();
    const[filterOpen, setFilterOpen] = useState(false);
    const[filtered, setFiltered] = useState(false);
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[, setEntitySortOpen] = useState();
    const[updateEmailQueueData, setUpdateEmailQueueData] = useState(false);
    const[emailQueueFilteredData, setEmailQueueFilteredData] = useState({
        recipient_entity_id: "",
        subject: "",
        template: "",
        body: "",
        time_created: "",
        time_updated: "",
        id: "",
        interval: "",
        sent_status: "",
    });
    const[emailQueueSorting, setEmailQueueSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
    });
    const sort_array = [
        {id: "id", name:"ID"},
        {id: "recipient_entity_id", name: "Recipient Entity ID"},
        {id: "subject", name:"Subject"},
        {id: "interval", name:"Interval"},
        {id: "template", name:"Template"},
        {id: "body", name:"Body"},
        {id: "sent_status", name:"Status"},
        {id: "time_created", name:"Time Created"},
        {id: "time_updated", name:"Time Updated"},
    ];
    
    const[sorted, setSorted] = useState(false);
    const[sortOpen, setSortOpen] = useState();
    const filterRef = useRef(null);
    const sortRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            // Close the modal here
            setFilterOpen(false);
        }
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            // Close the modal here
            setSortOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEmailQueueFetch = (e) => {
        for (const key in emailQueueFilteredData) {
            if (emailQueueFilteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        for (const key in emailQueueSorting) {
            if (emailQueueSorting[key] !== "" && emailQueueSorting[key] !== 'asc') {
                setSorted(true);
                break;
            }
        }

        fetchEmailQueue(projectId, emailQueueFilteredData, emailQueueSorting, setEmailQueue, setFilterOpen, setSortOpen);
        setUpdateEmailQueueData(false);
    };

    useEffect(() => {
        handleEmailQueueFetch();
    }, [updateEmailQueueData]);

    useEffect(()=>{
        const entityFilteredData = {code: "", name: "", active: "true", entity_type_id:"", entity_type_name:""}
        fetchEntities(setEntities, entityFilteredData, setFilterOpen, entityListSorting, setEntitySortOpen);
    }, []);

    function clearFilter() {
        const emptyFilterData = Object.keys(emailQueueFilteredData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setEmailQueueFilteredData(emptyFilterData);
        setFiltered(false);
        setUpdateEmailQueueData(true);
    }

    function clearSort() {
        const emptySortingData = Object.keys(emailQueueSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setEmailQueueSorting(emptySortingData);
        setSorted(false);
        setUpdateEmailQueueData(true);
    }

    return <>
        {projectId && <h2><div style={{color:"#3178C6"}}>Project {localStorage.getItem('projectname')}</div><div style={{color:"gray"}}>Email Queue</div></h2>}
        {!projectId && <div>
            <h2><div style={{color:"red"}}>Project Not Selected</div>
                <div style={{color:"gray"}}>Email Queue</div>
            </h2>
            <em style={{color:"red"}}>Please select a project on the Home page</em>
        </div>}

        {projectId && <>
            <button className='grayButton' onClick={()=> setFilterOpen(!filterOpen)}>Filter</button>
            {filtered && <button className='grayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
            {!filtered && <button className='lightGrayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <button className='grayButton' onClick={()=> setSortOpen(!sortOpen)}>Sort</button>
            {sorted && <button className='grayButton' onClick={()=> clearSort()}>Clear Sort</button>}
            {!sorted && <button className='lightGrayButton' onClick={()=> clearSort()}>Clear Sort</button>}
            {filterOpen ? (
                <ul className="menu fortyPercentMenu" ref={filterRef}>
                    <li className="menu-item">
                        Email Queue ID
                        <input type="text" name="id" defaultValue={emailQueueFilteredData.id} placeholder="Email Queue ID"
                               onChange={(e) =>
                                   setEmailQueueFilteredData( {...emailQueueFilteredData, id: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Recipient Entity ID <br />
                        <select  name="recipient_entity_id" defaultValue={emailQueueFilteredData.recipient_entity_id}
                                 onChange={(e) =>
                                     setEmailQueueFilteredData( {...emailQueueFilteredData, recipient_entity_id: e.target.value})}>
                            <option key={0} value={0}>Select Recipient Entity</option>
                            {entities && entities.map((entity,i)=> {
                                return (
                                    <option key={entity.id} value={entity.id}>{entity.entity_name}</option>
                                )
                            })}
                        </select>
                    </li>

                    <li className="menu-item">
                        Subject
                        <input type="text" defaultValue={emailQueueFilteredData.subject} name="subject" placeholder="Subject"
                               onChange={(e) =>
                                   setEmailQueueFilteredData( {...emailQueueFilteredData, subject: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Interval
                        <input type="text" defaultValue={emailQueueFilteredData.interval} name="interval" placeholder="interval"
                               onChange={(e) =>
                                   setEmailQueueFilteredData( {...emailQueueFilteredData, interval: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Body
                        <input type="text" defaultValue={emailQueueFilteredData.body} name="body" placeholder="Body"
                               onChange={(e) =>
                                   setEmailQueueFilteredData( {...emailQueueFilteredData, body: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Status <br />
                        <select name="sent_status" defaultValue={emailQueueFilteredData.sent_status} onChange={(e) =>
                            setEmailQueueFilteredData( {...emailQueueFilteredData, sent_status: e.target.value})}>
                            <option value={true}>Sent</option>
                            <option value={false}>Not Sent</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Time Created
                        <input type="date" defaultValue={emailQueueFilteredData.time_created}
                               onChange={(e) =>
                                   setEmailQueueFilteredData({...emailQueueFilteredData, time_created: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Time Updated
                        <input type="date" defaultValue={emailQueueFilteredData.time_updated}
                               onChange={(e) =>
                                   setEmailQueueFilteredData({...emailQueueFilteredData, time_updated: e.target.value})}>
                        </input>
                    </li>

                    <li>
                        <button onClick={()=> handleEmailQueueFetch()}>Apply</button>
                    </li>
                </ul>
            ) : null}
            {sortOpen ? (
                <ul className="menu fiftyOnePercentMenu"  ref={sortRef}>
                    <li className="menu-item">
                        Sort By
                        <select name="id" defaultValue={emailQueueSorting.sort_one}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_one: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>

                        <select name="sort_one_direction" defaultValue={emailQueueSorting.sort_one_direction}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_one_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_two" defaultValue={emailQueueSorting.sort_two}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_two: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_two_direction" defaultValue={emailQueueSorting.sort_two_direction}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_two_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_three" defaultValue={emailQueueSorting.sort_three}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_three: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_three_direction" defaultValue={emailQueueSorting.sort_three_direction}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_three_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_four" defaultValue={emailQueueSorting.sort_four}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_four: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_four_direction" defaultValue={emailQueueSorting.sort_four_direction}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_four_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_five" defaultValue={emailQueueSorting.sort_five}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_five: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_five_direction" defaultValue={emailQueueSorting.sort_five_direction}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_five_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_six" defaultValue={emailQueueSorting.sort_six}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_six: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_six_direction" defaultValue={emailQueueSorting.sort_six_direction}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_six_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_seven" defaultValue={emailQueueSorting.sort_seven}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_seven: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_seven_direction" defaultValue={emailQueueSorting.sort_seven_direction}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_seven_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_eight" defaultValue={emailQueueSorting.sort_eight}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_eight: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_eight_direction" defaultValue={emailQueueSorting.sort_eight_direction}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_eight_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_nine" defaultValue={emailQueueSorting.sort_nine}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_nine: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_nine_direction" defaultValue={emailQueueSorting.sort_nine_direction}
                                onChange={(e) =>
                                    setEmailQueueSorting({...emailQueueSorting, sort_nine_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li>
                        <button className="grayButton" onClick={handleEmailQueueFetch}>Apply</button>
                    </li>
                </ul>
            ) : null}
            <table key="dupeQueueTable" className="sellerListTable">
                <thead>
                    <tr className="outline">
                    <td className="outline"><b>ID</b></td>
                    <td className="outline"><b>Recipient Entity ID</b></td>
                    <td className="outline"><b>Subject</b></td>
                    <td className="outline"><b>Interval</b></td>
                    <td className="outline"><b>Template</b></td>
                    <td className="outline"><b>Body</b></td>
                    <td className="outline"><b>Status</b></td>
                    <td className="outline"><b>Time Created</b></td>
                    <td className="outline"><b>Time Updated</b></td>
                </tr>
                </thead>
                <tbody>
                {emailQueues.length > 0 && emailQueues.map((emailQueue,i)=>{
                    let sent_status = "Not Sent";
                    if (emailQueue.sent_status) {
                        sent_status = "Sent"
                    }
                    let time_created = "";
                    let time_updated = "";
                    if (emailQueue.time_created !== '') { time_created = calculate_date_time(emailQueue.time_created) }
                    if (emailQueue.time_updated !== '' && emailQueue.time_updated != null) { time_updated = calculate_date_time(emailQueue.time_updated) }
                    return (<>
                        <tr key={"emailQueue"+emailQueue.id}>
                            <td className="outline">{emailQueue.id}</td>
                            <td className="outline">{emailQueue.entity_name}</td>
                            <td className="outline">{emailQueue.subject}</td>
                            <td className="outline">{emailQueue.interval}</td>
                            <td className="outline">{emailQueue.template}</td>
                            <td className="outline">{emailQueue.body}</td>
                            <td className="outline">{sent_status}</td>
                            <td className="outline">{time_created}</td>
                            <td className="outline">{time_updated}</td>
                        </tr>
                    </>)
                })}
                </tbody>
            </table>
        </>}
    </>
}